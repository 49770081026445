import styled from 'styled-components'

export const Styles = styled.div`
	.modal-container{
		
	}
    .logoREVCON{
        width: 122px;
        height: 83px;
    }
	.close-btn{
        color: white
    }

    .main-btn{
        border-radius: 15px;
        background: #00AEFF 0% 0% no-repeat padding-box;
		width: 50%;
		text-align: center
        border: none;
    }

    .title{
        text-align: left;
        font: normal normal 300 21px/26px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .bold-title{
        text-align: left;
        font: normal normal bold 21px/26px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
`
