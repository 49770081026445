import styled from 'styled-components'

const LevelOneStyles = styled.div`
  width:100%;
  min-height:100%;
  //background: transparent linear-gradient(to top, #6F3CEA 0%, #25144D 18%, #000000 100%) 0% 0% no-repeat padding-box;
  //background: transparent linear-gradient( to top, #9D1915 0%, #5b1b19 26%, #280d0c 80%, #000000 100%) 0% 0% no-repeat padding-box;
  background: #000000;
  .information-content{
    padding:20px;
    width:100%;
    height:100%;
    /* position: absolute; */
    .title-content{
      display:flex;
      align-items:flex-end;
      margin:61px 0px 32px 0px;
      h1{
        font-size:32px;
        color:white;
        font-weight:700;
        z-index:100;
        margin:0;
      }
      .dot{
        height: 6px;
        width: 6px;
        background-color: #D72B52;
        border-radius: 50%;
        display: inline-block;
        margin:0px 0px 5px 3px;
        }
    }
    .information-system{
      margin:50px 0px 0px 0px;
      padding-left:20px;
      display:flex;
      img{
        height: min-content;
        width:min-content;
        z-index:50;
      }
      p{
        font-size:14px;
        margin-left:33px;
        line-height:16px;
        color:white;
        text-align:left;
        z-index:100;
        font-weight:500;
      }
    }
  }
  .systems-content{
    margin-top:70px;
    overflow: hidden;
    .title{
      display:flex;
      align-items:center;
      margin:15px 0px 0px 0px;
      z-index: 100;
      h1{
        font-size:16px;
        color:white;
        font-weight:700;
        margin:0;
        z-index: 100;
      }
    }
  }
  .system-content:first-child{
    .title{
      margin-top:65px;
    }
  }
  .image-violet-wave-1{
    width: 100%;
    position: absolute;
    top: 400px;
  }
  .image-violet-wave-2{
    width: 100%;
    position: absolute;
    top:${(props) => props.openSmartImplants ?  "1500px" : "53%"};
    left:0;
  }
  .image-violet-wave-3{
    width: 100%;
    position: absolute;
    top: 38%;
  }
  .catalog-button{
    width: 100%;
    /* margin: initial; */
    margin-top:45px;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:12px;
      font-weight:700;
      width: 70%;
      border: 1px solid white;
      border-radius: 30px;
      color:white;
      height: 40px;
    }
  }
  .universal-expanders-content{
    margin-top:45px;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    .text{
      display:flex;
      width: 100%;
      margin-left:5px;
      p{
        color:white;
        font-size:15px;
        line-height:17px;
        font-weight:400;
        width:264px;
      }
    }
    img{
      width:min-content;
      margin:60px 0px;
      z-index: 50;
    }
  }

  .accordion{
    padding: 0 5% 5% 5%;
  }
`
export default LevelOneStyles;