import React from 'react'
import { Row, Col, Image } from 'react-bootstrap';
import { navigate } from 'hookrouter';
import styled from 'styled-components'
import AccordionCard from './AccordionCard';
import { Trans } from 'react-i18next';
import RegisterMark from '../../components/registerMark';

const Styles = styled.div`
  .card{
    z-index:10;
    z-index:800;
    //background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(-90deg,#9D1915 0%, #470503 100%) 0% 0% no-repeat padding-box;
    .card-header{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      margin-top:15px;
    }
    .border-title{
      border:1px solid white;
      margin-bottom:5px;
      width:94%;
    }
    .card-title{
      h1{
        font-size:16px;
        font-weight:700;
      }
    }
    .container-toggle{
      height: 100%;
      width:100%;
      display: flex;
      align-items: flex-end;
    }
  }
  .accordion-collapse{
    z-index: 5;
    position: absolute;
    width: 100%;
    top: 84px;
    z-index:700;
  }
`

const DentalLevelOneCardStyles = styled.div`
    width:100%;
    background:black;
    padding: 40px 0px 20px 20px;
    z-index:999;
    .system-content{
      height:max-content;
      width:100;
      z-index:999;
      .card-title{
        font-size:16px;
        font-weight:700;
      }
      .border-title{
        margin:30px 0px 20px 0px;
      }
      .first-col{
        display:flex;
        justify-content:center;
        align-items:flex-start;
      }
    }
    .system-content:last-child{
      .border-title{
        display:none;
      }
    }

`
export const AccordionDental = ({ systems, system }) => {
	const renderSystems = () => {
		let render = [];
		for (const key in systems) {
			if (key !== system) {
				render.push(
					<Row className="system-content" onClick={() => navigate(systems[key].url)}>
						<Col xs={3} className="first-col">
							<Image alt="" className="card-image" src={systems[key].image}></Image>
						</Col>
						<Col xs={8}>
							<div className="card-title">
								<h1><Trans>{systems[key].title}</Trans></h1>
							</div>
							<div className='border-title' />
						</Col>
					</Row>
				)
			}
		}
		return render;
	}
	const DentalLevelOneCard = () => {
		return (
			<DentalLevelOneCardStyles>
				{renderSystems()}
			</DentalLevelOneCardStyles>
		)
	}
	return (
		<Styles>
			<AccordionCard
				image={systems[system].image}
				title={<RegisterMark>{systems[system].title}</RegisterMark>}
				inside={false}
				className="my-card"
        content={<DentalLevelOneCard />}
        arrowColor={"white"}
			/>
		</Styles>
	)
}
export default AccordionDental