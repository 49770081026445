/*----- Core -----*/
import React, { useState } from "react";
import { navigate } from 'hookrouter'
import { Trans } from "react-i18next";
import i18n from "i18next";
import Subtitle from "../../../../components/text/Subtitle";
import { Image, Row, Container, Col, Table, Button } from "react-bootstrap";
import { Styles, ProductCardStyles } from "./Styles";
import dataGenerator from "./data.js";

import ImageTitleOneToOne from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/Logo_One-to-One_AoN_Implants.png"
/*Prótestica General */
import ImageCards1_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-1.png";
import ImageCards1_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-2.png";
import ImageCards1_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-3.png";
import ImageCards1_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-4.png";
import ImageCards1_5 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-5.png";
import ImageCards1_6 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-6.png";
import ImageCards1_7 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_1/image-card-7.png";

/*Second Row */
import ImageCards2_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_2/image-card-1.png";
import ImageCards2_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_2/image-card-2.png";

/*3POD*/
import ImageCards3_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-1.png";
import ImageCards3_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-2.png";
import ImageCards3_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-3.png";
import ImageCards3_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-4.png";
import ImageCards3_5 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-5.png";
import ImageCards3_6 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_3/image-card-6.png";
/*PRÓTESIS CEMENTADA*/
import ImageCards4_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_4/image-card-1.png";
import ImageCards4_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_4/image-card-2.png";
import ImageCards4_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_4/image-card-3.png";

/*PRÓTESIS Roscada*/
import ImageCards5_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-1.png";
import ImageCards5_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-2.png";
import ImageCards5_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-3.png";
import ImageCards5_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-4.png";
import ImageCards5_5 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-5.png";
import ImageCards5_6 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-8.png";
import ImageCards5_7 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_5/image-card-9.png";

/*PRÓTESIS CONOMÉTRICA*/
import ImageCards6_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-1.png";
import ImageCards6_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-2.png";
import ImageCards6_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-3.png";
import ImageCards6_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-4.png";
import ImageCards6_5 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-5.png";
import ImageCards6_6 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-6.png";
import ImageCards6_7 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-7.png";
import ImageCards6_8 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_6/image-card-8.png";

/* One to One */
import ImageCards7_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_7/image-card-1.png";
import ImageCards7_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_7/image-card-2.png";
import ImageCards7_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_7/image-card-3.png";
import ImageCards7_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_7/image-card-4.png";

/*PRÓTESIS OVERDENTURE*/
import ImageCards8_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_8/image-card-1.png";
import ImageCards8_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_8/OverDLock_Conical FVCON_Snappler.png";

/*Piezas de Repuesto*/
import ImageCards9_1 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-1.png";
import ImageCards9_2 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-2.png";
import ImageCards9_3 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-3.png";
import ImageCards9_4 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-4.png";
import ImageCards9_5 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-5.png";
import ImageCards9_6 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-6.png";
import ImageCards9_7 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-7.png";
import ImageCards9_8 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-8.png";
import ImageCards9_9 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-9.png";
import ImageCards9_10 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-10.png";
import ImageCards9_11 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-11.png";
import ImageCards9_12 from "../../../../assets/images/Dental/conicalConnection/prostheticComponents/cards_9/image-card-12.png";


import VioletWaveImg from "../../../../assets/images/onda-roja.png";

const TBaseImages = [ImageCards3_1, ImageCards3_2, ImageCards3_3, ImageCards3_4, ImageCards3_5, ImageCards3_6];
const protesicaGeneralImages = [ImageCards1_1, ImageCards1_2, ImageCards1_3, ImageCards1_4, ImageCards1_5, ImageCards1_6, ImageCards1_7]
const piezasDeRepuestoImages = [ImageCards9_1, ImageCards9_2, ImageCards9_3, ImageCards9_4, ImageCards9_5, ImageCards9_6, ImageCards9_7, ImageCards9_8, ImageCards9_9, ImageCards9_10, ImageCards9_11, ImageCards9_12]
const protesisConometricaImages = [ImageCards6_1, ImageCards6_2, ImageCards6_3, ImageCards6_4, ImageCards6_5, ImageCards6_6, ImageCards6_7, ImageCards6_8]
const protesisRoscadaImages = [ImageCards5_1, ImageCards5_2, ImageCards5_3, ImageCards5_4, ImageCards5_5, ImageCards5_6, ImageCards5_7];
const protesisCementadaImages = [ImageCards4_1, ImageCards4_2, ImageCards4_3]
const OneToOneImages = [ImageCards7_1, ImageCards7_2, ImageCards7_3, ImageCards7_4]

const widthImages_1 = ["40%", "50%", "24%", "75%", "50%", "35%", "55%", "50%", "50%", "50%", "50%", "50%"]
const widthImages_2 = ["40%", "40%", "50%"]
const widthImages_3 = ["36%", "36%", "49%", "23%", "14%", "49%"]
const widthImages_4 = ["35%", "35%", "34%", "50%", "50%", "50%", "50%", "50%", "50%", "50%", "50%", "50%"]
const widthImages_5 = ["63%", "42%", "50%", "55%", "50%", "55%", "55%", "50%", "49%", "50%"]
const widthImages_6 = ["75%", "20%", "39%", "41%", "44%", "69%", "80%", "40%"]
const widthImages_7 = ["16%", "7%", "33%", "10%", "12%"]
const widthImages_8 = ["65%", "40%"]
const widthImages_9 = ["30%", "28%", "50%", "40%", "58%", "30%", "65%", "52%", "100%", "83%", "90%", "51%"]

const ProstheticComponents = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function (lng) {
		setData(dataGenerator(lng))
	})
	function renderProtesicaGeneral() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={[data.cards_1[0], data.cards_1[1]]}
					images={[protesicaGeneralImages[0], protesicaGeneralImages[1]]}
					widthImages={[widthImages_1[0], widthImages_1[1]]}
					subtitles={["", ""]}
					title={"CCprostheticComponents.cards_1.card_1.title"}
					cardType={4}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					image={protesicaGeneralImages[2]}
					title={"CCprostheticComponents.cards_1.card_2.title"}
					subtitles={["CCprostheticComponents.cards_1.card_2.subtitle_1", "CCprostheticComponents.cards_1.card_2.subtitle_2"]}
					subtitle={""}
					cardData={data.cards_1[2]}
					cardType={2}
					cantElements={2}
					widthImage={widthImages_1[2]}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					image={protesicaGeneralImages[3]}
					title={"CCprostheticComponents.cards_1.card_3.title"}
					subtitle={""}
					cardData={data.cards_1[3]}
					cardType={1}
					widthImage={widthImages_1[3]}
				></ProductCard>
			</Col>
		)
		for (let i = 4; i < 7; i++) {
			render.push(
				<Col className="zIndex100" md={4}>
					<ProductCard
						image={protesicaGeneralImages[i]}
						title={"CCprostheticComponents.cards_1.card_" + i + ".title"}
						subtitle={""}
						cardData={data.cards_1[i]}
						cardType={2}
						widthImage={widthImages_1[i]}
						cantElements={2}
						cardInformation={"CCprostheticComponents.cards_1.card_" + i + ".cardInformation"}
					></ProductCard>
				</Col>
			)
		}

		return render;
	}
	function renderProtesisProvisional() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={8}>
				<ProductCard
					image={ImageCards2_1}
					title={"CCprostheticComponents.cards_2_1.card_1.title"}
					subtitle={""}
					subtitles={["CCprostheticComponents.cards_2_1.card_1.subtitle_1", "CCprostheticComponents.cards_2_1.card_1.subtitle_2"]}
					cardData={data.cards_2[0]}
					cardType={2}
					widthImage={widthImages_2[0]}
					cantElements={2}
					cardInformation={"CCprostheticComponents.cards_2_1.card_1.cardInformation"}
				></ProductCard>
			</Col>
		)
		return render;
	}
	function renderProtesisZ() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={8}>
				<ProductCard
					cardData={data.cards_2[1]}
					title={"CCprostheticComponents.cards_2_2.card_1.title"}
					subtitle={""}
					cardInformation={"CCprostheticComponents.cards_2_2.card_1.cardInformation"}
					image={ImageCards2_2}
					widthImage={widthImages_2[1]}
					cardType={2}
					cantElements={1}
				></ProductCard>
			</Col>
		)
		return render
	}
	function renderTBase() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={3}>
				<ProductCard
					cardData={data.cards_3[0]}
					title={"CCprostheticComponents.cards_3.card_1.title"}
					subtitle={""}
					cardInformation={"CCprostheticComponents.cards_3.card_1.cardInformation"}
					image={TBaseImages[0]}
					widthImage={widthImages_3[0]}
					cardType={2}
					cantElements={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={3}>
				<ProductCard
					cardData={data.cards_3[1]}
					title={"CCprostheticComponents.cards_3.card_2.title"}
					subtitle={""}
					cardInformation={"CCprostheticComponents.cards_3.card_2.cardInformation"}
					image={TBaseImages[1]}
					widthImage={widthImages_3[1]}
					cardType={2}
					cantElements={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					image={TBaseImages[2]}
					title={"CCprostheticComponents.cards_3.card_3.title"}
					subtitle={"CCprostheticComponents.cards_3.card_3.subtitle"}
					cardData={data.cards_3[2]}
					cardType={1}
					widthImage={widthImages_3[2]}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" xl={4}>
				<ProductCard
					cardData={data.cards_3[3]}
					cardData_2={data.cards_3[4]}
					image={TBaseImages[3]}
					image_2={TBaseImages[4]}
					cardType={3}
					widthImage={widthImages_3[3]}
					widthImage_2={widthImages_3[4]}
					title={"CCprostheticComponents.cards_3.card_4.title"}
				></ProductCard>
			</Col>
		)
		return render;
	}
	function renderProtesisCementada() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={data.cards_4[0]}
					title={"CCprostheticComponents.cards_4.card_1.title"}
					subtitle={""}
					subtitles={["CCprostheticComponents.cards_4.card_1.subtitle_1", "CCprostheticComponents.cards_4.card_1.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_4.card_1.cardInformation"}
					image={protesisCementadaImages[0]}
					widthImage={widthImages_4[0]}
					cardType={2}
					cantElements={2}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={data.cards_4[1]}
					title={"CCprostheticComponents.cards_4.card_2.title"}
					subtitle={""}
					subtitles={["CCprostheticComponents.cards_4.card_2.subtitle_1", "CCprostheticComponents.cards_4.card_2.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_4.card_2.cardInformation"}
					image={protesisCementadaImages[1]}
					widthImage={widthImages_4[1]}
					cardType={2}
					cantElements={2}
				></ProductCard>
			</Col>
		)
		return render;
	}
	function renderProtesisRoscada() {
		let render = [];

		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_5[0]}
					title={"CCprostheticComponents.cards_5.card_1.title"}
					subtitle={""}
					image={protesisRoscadaImages[0]}
					widthImage={widthImages_5[0]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={3}>
				<ProductCard
					cardData={data.cards_5[1]}
					title={"CCprostheticComponents.cards_5.card_2.title"}
					subtitle={""}
					cardInformation={"CCprostheticComponents.cards_5.card_2.cardInformation"}
					image={protesisRoscadaImages[1]}
					widthImage={widthImages_5[1]}
					cardType={2}
					cantElements={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_5[2]}
					title={"CCprostheticComponents.cards_5.card_3.title"}
					subtitle={""}
					productDetail={["CCprostheticComponents.cards_5.card_3.productDetail_1", "CCprostheticComponents.cards_5.card_3.productDetail_2"]}
					cardInformation={"CCprostheticComponents.cards_5.card_3.cardInformation"}
					image={protesisRoscadaImages[2]}
					widthImage={widthImages_5[2]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		for (let i = 3; i < 7; i++) {
			render.push(
				<Col className="zIndex100" md={2}>
					<ProductCard
						cardData={data.cards_5[i]}
						title={"CCprostheticComponents.cards_5.card_" + (i + 1) + ".title"}
						subtitle={""}
						cardInformation={"CCprostheticComponents.cards_5.card_" + (i + 1) + ".cardInformation"}
						image={protesisRoscadaImages[i]}
						widthImage={widthImages_5[i]}
						cardType={1}
					></ProductCard>
				</Col>
			)
		}

		return render;
	}
	function renderProtesisCronometrica() {
		let render = [];

		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_6[0]}
					title={"CCprostheticComponents.cards_6.card_1.title"}
					subtitle={""}
					image={protesisConometricaImages[0]}
					widthImage={widthImages_6[0]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={6}>
				<ProductCard
					cardData={data.cards_6[1]}
					title={"CCprostheticComponents.cards_6.card_2.title"}
					subtitle={""}
					cardInformation={"CCprostheticComponents.cards_6.card_2.cardInformation"}
					image={protesisConometricaImages[1]}
					widthImage={widthImages_6[1]}
					cardType={2}
					cantElements={3}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={6}>
				<ProductCard
					cardData={[data.cards_6[2], data.cards_6[3], data.cards_6[4]]}
					images={[protesisConometricaImages[2], protesisConometricaImages[3], protesisConometricaImages[4]]}
					widthImages={[widthImages_6[2], widthImages_6[3], widthImages_6[4]]}
					subtitles={["CCprostheticComponents.cards_6.card_3.subtitle_1", "CCprostheticComponents.cards_6.card_3.subtitle_2", "CCprostheticComponents.cards_6.card_3.subtitle_3"]}
					title={"CCprostheticComponents.cards_6.card_3.title"}
					cardInformation={"CCprostheticComponents.cards_6.card_3.cardInformation"}
					cardType={4}
					cantElements={3}
				></ProductCard>
			</Col>
		)
		for (let i = 5; i < 8; i++) {
			render.push(
				<Col className="zIndex100" md={2}>
					<ProductCard
						cardData={data.cards_6[i]}
						title={"CCprostheticComponents.cards_6.card_" + (i - 1) + ".title"}
						subtitle={""}
						image={protesisConometricaImages[i]}
						widthImage={widthImages_6[i]}
						cardInformation={"CCprostheticComponents.cards_6.card_" + (i - 1) + ".cardInformation"}
						cardType={1}
					></ProductCard>
				</Col>
			)
		}
		return render;
	}
	function renderOneToOne() {
		let render = [];
		for (let i = 0; i < 2; i++) {
			render.push(
				<Col className="zIndex100" md={6}>
					<ProductCard
						cardData={data.cards_7[i]}
						title={"CCprostheticComponents.cards_7.card_" + (i + 1) + ".title"}
						subtitle={""}
						subtitles={["CCprostheticComponents.cards_7.card_" + (i + 1) + ".subtitle_1", "CCprostheticComponents.cards_7.card_" + (i + 1) + ".subtitle_2", "CCprostheticComponents.cards_7.card_" + (i + 1) + ".subtitle_3"]}
						image={OneToOneImages[i]}
						widthImage={widthImages_7[i]}
						cardType={2}
						cantElements={3}
					></ProductCard>
				</Col>
			)
		}
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_7[2]}
					title={"CCprostheticComponents.cards_7.card_3.title"}
					subtitle={""}
					image={OneToOneImages[2]}
					widthImage={widthImages_7[2]}
					cardInformation={"CCprostheticComponents.cards_7.card_3.cardInformation"}
					cardType={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={6}>
				<ProductCard
					cardData={data.cards_7[3]}
					title={"CCprostheticComponents.cards_7.card_4.title"}
					subtitle={""}
					subtitles={["CCprostheticComponents.cards_7.card_4.subtitle_1", "CCprostheticComponents.cards_7.card_4.subtitle_2", "CCprostheticComponents.cards_7.card_4.subtitle_3"]}
					productDetail={["CCprostheticComponents.cards_7.card_4.productDetail_1", "CCprostheticComponents.cards_7.card_4.productDetail_2"]}
					image={OneToOneImages[3]}
					widthImage={widthImages_7[3]}
					cardType={2}
					cantElements={3}
				></ProductCard>
			</Col>
		)
		// render.push(
		// 	<Col className="zIndex100" md={6}>
		// 		<ProductCard
		// 			cardData={data.cards_7[4]}
		// 			title={"CCprostheticComponents.cards_7.card_5.title"}
		// 			subtitle={""}
		// 			subtitles={["CCprostheticComponents.cards_7.card_5.subtitle_1", "CCprostheticComponents.cards_7.card_5.subtitle_2", "CCprostheticComponents.cards_7.card_5.subtitle_3"]}
		// 			productDetail={["CCprostheticComponents.cards_7.card_5.productDetail_1", "CCprostheticComponents.cards_7.card_5.productDetail_2"]}
		// 			image={EmptyImage}
		// 			widthImage={widthImages_7[4]}
		// 			cardType={2}
		// 			cantElements={3}
		// 		></ProductCard>
		// 	</Col>
		// )
		return render;
	}
	function renderProtesisOverdenture() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_8[0]}
					title={"CCprostheticComponents.cards_8.card_1.title"}
					subtitle={""}
					image={ImageCards8_1}
					widthImage={widthImages_8[0]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_8[1]}
					title={"CCprostheticComponents.cards_8.card_2.title"}
					subtitle={""}
					image={ImageCards8_2}
					widthImage={widthImages_8[1]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		return render;
	}
	function renderPiezas() {
		let render = [];
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_9[0]}
					title={"CCprostheticComponents.cards_9.card_1.title"}
					subtitle={""}
					productDetail={["CCprostheticComponents.cards_9.card_1.productDetail_1", "CCprostheticComponents.cards_9.card_1.productDetail_2", "CCprostheticComponents.cards_9.card_1.productDetail_3"]}
					image={piezasDeRepuestoImages[0]}
					widthImage={widthImages_9[0]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		for (let i = 1; i < 3; i++) {
			render.push(
				<Col className="zIndex100" md={2}>
					<ProductCard
						cardData={data.cards_9[i]}
						title={"CCprostheticComponents.cards_9.card_" + (i + 1) + ".title"}
						subtitle={""}
						productDetail={["CCprostheticComponents.cards_9.card_" + (i + 1) + ".productDetail_1"]}
						image={piezasDeRepuestoImages[i]}
						widthImage={widthImages_9[i]}
						cardType={1}
					></ProductCard>
				</Col>
			)
		}
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={[data.cards_9[3], data.cards_9[4]]}
					images={[piezasDeRepuestoImages[3], piezasDeRepuestoImages[4]]}
					widthImages={[widthImages_9[3], widthImages_9[4]]}
					subtitles={["CCprostheticComponents.cards_9.card_4.subtitle_1", "CCprostheticComponents.cards_9.card_4.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_9.card_4.cardInformation"}
					productDetail={["CCprostheticComponents.cards_9.card_4.productDetail_1", "CCprostheticComponents.cards_9.card_4.productDetail_2"]}
					title={"CCprostheticComponents.cards_9.card_4.title"}
					cardType={4}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={2}>
				<ProductCard
					cardData={data.cards_9[5]}
					title={"CCprostheticComponents.cards_9.card_5.title"}
					subtitle={"CCprostheticComponents.cards_9.card_5.subtitle"}
					subtitles={["CCprostheticComponents.cards_9.card_5.subtitle_1"]}
					cardInformation={"CCprostheticComponents.cards_9.card_5.cardInformation"}
					image={piezasDeRepuestoImages[5]}
					widthImage={widthImages_9[5]}
					cardType={1}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={[data.cards_9[6], data.cards_9[7]]}
					title={"CCprostheticComponents.cards_9.card_6.title"}
					subtitles={["CCprostheticComponents.cards_9.card_6.subtitle_1", "CCprostheticComponents.cards_9.card_6.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_9.card_6.cardInformation"}
					images={[piezasDeRepuestoImages[6], piezasDeRepuestoImages[7]]}
					widthImages={[widthImages_9[6], widthImages_9[7]]}
					cardType={4}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={[data.cards_9[8], data.cards_9[9]]}
					title={"CCprostheticComponents.cards_9.card_7.title"}
					subtitles={["CCprostheticComponents.cards_9.card_7.subtitle_1", "CCprostheticComponents.cards_9.card_7.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_9.card_7.cardInformation"}
					images={[piezasDeRepuestoImages[8], piezasDeRepuestoImages[9]]}
					widthImages={[widthImages_9[8], widthImages_9[9]]}
					cardType={4}
				></ProductCard>
			</Col>
		)
		render.push(
			<Col className="zIndex100" md={4}>
				<ProductCard
					cardData={[data.cards_9[10], data.cards_9[11]]}
					title={"CCprostheticComponents.cards_9.card_8.title"}
					subtitles={["CCprostheticComponents.cards_9.card_8.subtitle_1", "CCprostheticComponents.cards_9.card_8.subtitle_2"]}
					cardInformation={"CCprostheticComponents.cards_9.card_8.cardInformation"}
					images={[piezasDeRepuestoImages[10], piezasDeRepuestoImages[11]]}
					widthImages={[widthImages_9[10], widthImages_9[11]]}
					cardType={4}
				></ProductCard>
			</Col>
		)
		return render;
	}
	return (
		<Styles>
			<div className="content-images-wave">
				<Image src={VioletWaveImg} className="img-fluid image-wave"></Image>
				<Image src={VioletWaveImg} className="img-fluid image-wave-reverse"></Image>
			</div>
			<div className="container-page">
				<h1 class="title"><Trans>CCprostheticComponents.self</Trans></h1>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_1</Trans></h1>
					<Row className="content-cards">
						{renderProtesicaGeneral()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<Row>
						<Col>
							<h1><Trans>CCprostheticComponents.title_2_1</Trans></h1>
							<Row className="content-cards">
								{renderProtesisProvisional()}
							</Row>
						</Col>
						<Col>
							<h1><Trans>CCprostheticComponents.title_2_2</Trans></h1>
							<Row className="content-cards">
								{renderProtesisZ()}
							</Row>
						</Col>
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_3</Trans></h1>
					<Row className="content-cards">
						{renderTBase()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_4</Trans></h1>
					<Row className="content-cards">
						{renderProtesisCementada()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_5</Trans></h1>
					<Row className="content-cards">
						{renderProtesisRoscada()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_6</Trans></h1>
					<Row className="content-cards">
						{renderProtesisCronometrica()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<div className="content-title-OneToOne">
						<Image src={ImageTitleOneToOne}></Image>
						<h1><Trans>CCprostheticComponents.title_7</Trans></h1>
					</div>
					<Row className="content-cards">
						{renderOneToOne()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_8</Trans></h1>
					<Row className="content-cards">
						{renderProtesisOverdenture()}
					</Row>
				</Container>
				<Container fluid={true} className="row-content">
					<h1><Trans>CCprostheticComponents.title_9</Trans></h1>
					<Row className="content-cards">
						{renderPiezas()}
					</Row>
				</Container>
				<div className="mt-5">
					<Subtitle content={<Trans>CCprostheticComponents.note</Trans>} color={"White"} />
				</div>
				<div className="content-buttons mb-5">
					<Button onClick={() => navigate('/dental/conicalConnection/newSmartImplants')}><Trans>dental.conicalConnection_modal.card_1.title</Trans></Button>
					<Button onClick={() => navigate('/dental/conicalConnection/surgicalInstruments')}><Trans>dental.conicalConnection_modal.card_3.title</Trans></Button>
				</div>
			</div>
		</Styles>
	);
}

const ProductCard = (props) => {
	function renderCard_1() {
		return (
			<div className="card-content">
				<div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				<h3 className="card-subtitle"><Trans>{props.subtitle}</Trans></h3>
				{generateCodesFirst(props.cardData)}
				<h3 className="subtitle_type1"><Trans>{props.subtitles ? props.subtitles[0] : null}</Trans></h3>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	function renderTable() {
		let render = []
		for (let i = 0; i < props.cantElements; i++) {
			render.push(
				<>
					<Table className="table-content mr-5">
						<thead>
							<h3 className="card-subtitle"><Trans>{props.subtitles ? props.subtitles[i] : null}</Trans></h3>
							<tr>
								<td></td>
								<td></td>
								{props.cardData.products_type[0][0] !== "" ? <td></td> : null}
							</tr>
						</thead>
						<tbody>
							{generateCodesSecond(props.cardData.products_cod[i], props.cardData.products_type[i], props.cardData.products_detail[i])}
						</tbody>
					</Table>
				</>
			)
		}
		return render
	}
	function renderCard_2() {
		return (
			<div className="card-content">
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				<div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
				<div className="d-flex">
					{renderTable()}
				</div>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	function renderCard_3() {
		return (
			<div className="cards-content-doble">
				<div className=" d-flex justify-content-around">
					<div className="content-image" style={{ width: props.widthImage }} ><Image src={props.image} className="card-image img-fluid" ></Image></div>
					<div className="content-image" style={{ width: props.widthImage_2 }} ><Image src={props.image_2} className="card-image img-fluid" ></Image></div>
				</div>
				<h2 className="card-title"><Trans>{props.title}</Trans></h2>
				<div className="card-content-info">
					<div className="card-content-doble">
						{generateCodesFirst(props.cardData)}
					</div>
					<div className="card-content-doble">
						{generateCodesFirst(props.cardData_2)}
					</div>
				</div>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	function renderCard_4() {
		let renderTable = [];
		let renderImages = []
		let i;
		for (i = 0; i < props.images.length; i++) {
			renderImages.push(
				<div className="content-image-type-4" >
					<Image src={props.images[i]} style={{ width: props.widthImages[i] }} className="card-image img-fluid" ></Image>
				</div>
			)
		}
		for (i = 0; i < props.images.length; i++) {
			renderTable.push(
				<Table className="table-content mr-3">
					<thead>
						<tr>
							<td className="td-header w-75"><Trans>{props.subtitles[i]}</Trans></td>
							<td></td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						{generateCodesSecond(props.cardData[i].products_cod, props.cardData[i].products_type)}
					</tbody>
				</Table>
			)
		}
		return (
			<div className="card-content">
				<h2 className="card-title mb-0"><Trans>{props.title}</Trans></h2>
				<h2 className="card-title"><Trans>{props.subtitle}</Trans></h2>
				<div className="d-flex">
					{renderImages}
				</div>
				<div className="d-flex">
					{renderTable}
				</div>
				{props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
			</div>
		)
	}
	function generateCodesSecond(products_cod, products_type, products_detail) {
		let table = [];
		for (let i = 0; i < products_cod.length; i++) {
			if (products_cod[i] !== "") {
				table.push(
					<>
						<tr>
							<td>{products_cod[i]}</td>
							{products_detail ? <td className="">{products_detail[i]}</td> : ""}
							{products_type !== "" ? <td className={products_detail ? "text-right" : ""}>{products_type[i]}</td> : null}
						</tr>
						{props.productDetail ? <p className="product-detail"><Trans>{props.productDetail[i]}</Trans></p> : null}
					</>
				)
			}
		}
		return table;
	}
	function generateCodesFirst(cardData) {
		let table = [];
		for (let i = 0; i < cardData.products_cod.length; i++) {
			if (cardData.products_cod[i] !== "") {
				table.push(
					<div>
						<div className="card-text">
							<p>{cardData.products_cod[i]}</p>
							<p>{cardData.products_type[i]}</p>
						</div>
						{props.productDetail ? <p className="product-detail"><Trans>{props.productDetail[i]}</Trans></p> : null}
					</div>
				)
			} else {
				table.push(
					<div className="card-text-empty">
						<p>&nbsp;&nbsp;</p>
					</div>
				)
			}
		}
		return table;
	}
	switch (props.cardType) {
	case 1:
		return (
			<ProductCardStyles>
				{renderCard_1()}
			</ProductCardStyles>
		)
	case 2:
		return (
			<ProductCardStyles>
				{renderCard_2()}
			</ProductCardStyles>
		)
	case 3:
		return (
			<ProductCardStyles>
				{renderCard_3()}
			</ProductCardStyles>
		)
	case 4:
		return (
			<ProductCardStyles>
				{renderCard_4()}
			</ProductCardStyles>
		)
	default: return (null)
	}
}
export default (ProstheticComponents);
