/*----- Core -----*/
import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";

/*----- Commons -----*/
import "../../../commons/i18n";
import { Trans } from "react-i18next";
import { DesktopStyles } from "../Styles.js"

/*----- Private -----*/
import data from "../Data"
import Catalog from "../Catalog"

import { ModalSmall } from '../../../components/modals/ModalSmall'
import DownloadModal from '../../../components/modals/DownloadModals/DownloadModal'

const Download = () => {

	const [openDownloadNavModal, setOpenDownloadNavModal] = useState(false);
	const DownloadNavModalContainer = ModalSmall(DownloadModal);

	function openDownloadNavModalCmd(){
		setOpenDownloadNavModal(true);
	}

	function closeDownloadNavModal(){
		setOpenDownloadNavModal(false);
	}

	useEffect(() => {
		const DownloadFormSubmited = localStorage.getItem("DownloadFormSubmited");
		//voy a llamar al modal para que se abra cuando se renderiza downloads
		//if(DownloadFormSubmited !== "true"){
			openDownloadNavModalCmd();
		//}
    }, [])

	const getItems = () => {
		return data.map( (item, idx)=>(
			<Catalog
				key = {idx}
				title = {item.title}
				image = {item.image}
				pdf = {item.pdf}
				filename = {item.filename}
				links = {item.links}
				name={item.name}
			/>
		))
	}

	return (
		<DesktopStyles>
			<div className="content-page">
				<div className="titles">
					<h1><Trans>descarga.title</Trans></h1>
				</div>
				<Container>
					<div className="row-content">
						{getItems()}
					</div>
				</Container>
			</div>
			<DownloadNavModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeDownloadNavModal}
					open={openDownloadNavModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="faico360"
				/>
		</DesktopStyles>
	);
};

export default Download;
