
import ConicalConnectionIcon from '../../../assets/images/Dental/LevelOneMobile/conicalConnectionIcon.png';
import InternalHexagonConnection from '../../../assets/images/Dental/LevelOneMobile/InternalHexagonConnection.png';
import UniversalExpanders from '../../../assets/images/Dental/LevelOneMobile/expansores.png';
import SurgicalGuided from '../../../assets/images/Dental/LevelOneMobile/surgicalGuided.png';

import ImageConexionHexagono from "../../../assets/images/Dental/LevelOneMobile/conexion_hexagono_interno.png";
import ConicConection from "../../../assets/images/Dental/LevelOneMobile/Conexion_Conica.png";
import UniversalSoldier from "../../../assets/images/Dental/LevelOneMobile/universalExpanders.png";

import IsFourImage from '../../../assets/images/Dental/LevelOneMobile/isFour.png';
import CYROTH from "../../../assets/images/Dental/conicalConnection/smartImplants/images-central/CYROTH.png";
import IsFourImage_IHC from "../../../assets/images/Dental/internalHexagonConnection/smartImplants/cards/card-image-three.png";
import CyrothImage from '../../../assets/images/Dental/LevelOneMobile/Cyroth-EI.png';
import CyrothShortImage from '../../../assets/images/Dental/LevelOneMobile/cyroth_nano.png';

import DentalCatalog from "../../../assets/pdf/dental-catalog.pdf"

const images = [ConicalConnectionIcon, InternalHexagonConnection, UniversalExpanders, SurgicalGuided];
const systemImages = [ConicConection, ImageConexionHexagono, UniversalSoldier, SurgicalGuided];

const systems = {
	conicalConnection: {
		image: images[0],
		systemImage: systemImages[0],
		texts: ["dental.conicalConnection_modal.texts.1", "dental.conicalConnection_modal.texts.2","dental.conicalConnection_modal.texts.3"],
		title: "dental.system_faico_dental.cards.1.title",
		url: '/dental/conicalConnection',
		catalog: DentalCatalog,
		smartImplants: {
			title: "dental.conicalConnection_modal.card_1.title",
			text: "CCsmartImplants.subtitle",
			cards: [
				{
					title: "CCsmartImplants.card_1.title",
					image: CYROTH,
					classImage: 'cyrothCC',
					texts: ["CCsmartImplants.card_1.content_1", "CCsmartImplants.card_1.content_2"],
					url: '/dental/conicalConnection/smartImplants/cyroth'
				},
				{
					title: "CCsmartImplants.card_2.title",
					image: IsFourImage,
					classImage: 'isFourCC',
					texts: ["CCsmartImplants.card_2.content_1", "CCsmartImplants.card_2.content_2"],
					url: '/dental/conicalConnection/smartImplants/isFour'
				}
			]
		},
		prostethicComponents: {
			title: "CCprostheticComponents.self",
			text: "dental.conicalConnection_modal.card_2.content",
			items: ["CCprostheticComponents.title_1", "CCprostheticComponents.title_2_1", "CCprostheticComponents.title_2_2", "CCprostheticComponents.title_3", "CCprostheticComponents.title_4", "CCprostheticComponents.title_5", "CCprostheticComponents.title_6", "CCprostheticComponents.title_7", "CCprostheticComponents.title_8"]
		},
		surgicalInstruments: {
			title: "CCsurgicalInstruments.self",
			text: "dental.conicalConnection_modal.card_3.content",
			items: ["CCsurgicalInstruments.title_1", "CCsurgicalInstruments.title_2", "CCsurgicalInstruments.title_3", "CCsurgicalInstruments.title_4", "CCsurgicalInstruments.cards_5.title", "CCsurgicalInstruments.cards_6.title"]
		}
	},
	internalHexagonConnection: {
		image: images[1],
		systemImage: systemImages[1],
		title: "dental.system_faico_dental.cards.2.title",
		texts: ["dental.conexionHexagono_modal.texts.1", "dental.conexionHexagono_modal.texts.2", "dental.conexionHexagono_modal.texts.3", "dental.conexionHexagono_modal.texts.4"],
		url: '/dental/internalHexagonConnection',
		catalog: DentalCatalog,
		smartImplants: {
			title: "dental.conexionHexagono_modal.card_1.title",
			text: "IHCsmartImplants.subtitle",
			cards: [
				{
					title: "IHCsmartImplants.card_1.title",
					image: CyrothImage,
					classImage: 'cyrothIHC',
					texts: ["IHCsmartImplants.card_1.content_1", "IHCsmartImplants.card_1.content_2"],
					url: '/dental/internalHexagonConnection/smartImplants/cyroth'
				},
				{
					title: "IHCsmartImplants.card_2.title",
					image: CyrothShortImage,
					classImage: 'cyrothShort',
					texts: ["IHCsmartImplants.card_2.content_1"],
					url: '/dental/internalHexagonConnection/smartImplants/cyrothShort'
				},
				{
					title: "IHCsmartImplants.card_3.title",
					image: IsFourImage_IHC,
					classImage: 'isFourIHC',
					texts: ["IHCsmartImplants.card_3.content_1", "IHCsmartImplants.card_3.content_2"],
					url: '/dental/internalHexagonConnection/smartImplants/isFour'
				}
			]
		},
		prostethicComponents: {
			title: "IHCprostheticComponents.self",
			text: "dental.conexionHexagono_modal.card_2.content",
			items: ["IHCprostheticComponents.title_1", "IHCprostheticComponents.title_2_2", "IHCprostheticComponents.title_2_1", "IHCprostheticComponents.title_3", "IHCprostheticComponents.title_4", "IHCprostheticComponents.title_5", "IHCprostheticComponents.title_6", "IHCprostheticComponents.title_9", "IHCprostheticComponents.title_7"]
		},
		surgicalInstruments: {
			title: "IHCsurgicalInstruments.self",
			text: "dental.conexionHexagono_modal.card_3.content",
			items: ["IHCsurgicalInstruments.cards_1.title", "IHCsurgicalInstruments.cards_4.title", "IHCsurgicalInstruments.cards_2.title", "IHCsurgicalInstruments.cards_3.title", "IHCsurgicalInstruments.cards_5.title", "IHCsurgicalInstruments.cards_6.title"]
		}
	},
	universalExpanders: {
		image: images[2],
		systemImage: systemImages[2],
		texts: ["universal.modal.texts.1"],
		title: "dental.system_faico_dental.cards.3.title",
		url: '/dental/universal_expanders',
		catalog: DentalCatalog,
	},
	SurgicalGuided: {
		image: images[3],
		systemImage: systemImages[3],
		title: "dental.system_faico_dental.cards.4.title",
		url: '/dental/guided_surgery'
	}
}
export default systems