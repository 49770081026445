import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const Main = styled.div`
`;

export const CommonRow = styled(Row)`
  padding: 205px 145px 185px 140px;
  display: flex;
  justify-content: center;
`;

export const FirstRow = styled(CommonRow)`
  //background: transparent linear-gradient(to bottom, #5B3D9C 0%, #25144D 10%, #000000 100%) 0% 0% no-repeat padding-box;
 background: transparent linear-gradient(180deg,#5b1b19 0%, #280d0c 100%) 0% 0% no-repeat padding-box;
 position: relative;
`;

export const SecondRow = styled(CommonRow)`
  //background: transparent linear-gradient(to bottom, #6F3CEA 0%, #160e2b 0%, #000000 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg,#5b1b19 0%, #000000 100%) 0% 0% no-repeat padding-box;
`;

export const ThirdRow = styled(CommonRow)`
  background-color: #0F0F0F;
`;

export const FourthRow = styled(CommonRow)`
  background-color: #0F0F0F;
  flex-direction: column;
  padding-top: 0px;
  align-items: flex-start;
`;

export const StyledRow = styled(Row)`
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index:104;
`;

export const StyledColCenter = styled(StyledCol)`
  align-items: center;
`;

export const StyledColFourthRow = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index:104;
  margin-top: 120px;
`;

export const Wave = styled.img`
  width: 100%;
  position: absolute;
  z-index:103;
`;

export const StyledImage = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export const StyledFirstImage = styled.img`
  width: 525px;
  height: 482px;
  margin-bottom: 103px;
  margin-top: -90px;
`;

export const CommonText = styled.p`
  font-family: "Montserrat", sans-serif;
  color: white;
  line-height: 32px;
  font-weight: 700;
`;

export const StyledTitleLarge = styled(CommonText)`
  font-size: 72px;

`;

export const StyledTitleMedium = styled(CommonText)`
  font-size: 60px;

`;

export const StyledTitleSmall = styled(CommonText)`
  font-size: 20px;

`;

export const StyledText = styled(CommonText)`
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 32px;
`;

export const StyledTextItalic = styled(CommonText)`
  font-size: 23px;
  font-weight: 300;
  font-style: italic;
`;

export const StyledColorText = styled.span`
  color: #00B7EA;
  font-weight: 700;
`;

export const SeparatedLine = styled.span`
  border: 1px solid #9D1915;
  opacity: 1;
  width: 100%;
  margin: 15px 0px 45px;
`;

export const Dot = styled.span`
  height: 15px;
  width: 15px;
  background-color: #D72B52;
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
`;