import styled from 'styled-components'

export const Styles = styled.div`
    display:flex;
		/* margin:2vmax 0px; */
    max-height:80vh;
    margin-top:20px;

    .col-card{
      min-height:320px;
    }
    .card-content{
      width: 324px;
      height: 350px;
      background:transparent !important;
      margin:0px !important;
      border:1.5px solid white;
      border-radius:10px !important;
      transition: transform .2s; /* Animation */
      height:100%;
      @media  screen and (max-width: 1440px) {
        width: 100%;
      }
    }
    .card-content:hover{
      transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
      
    }
    .card-body{
      display: flex;
      flex-direction: column;
    }
    .card-button{
      background:transparent !important;
      width:53%;
      border:1.5px solid white;
      border-radius:20px !important;
      font-family: 'Montserrat', sans-serif;
      font-weight:600;
      padding:10px 10px;
      margin-right:auto;
      margin-left:auto;
      margin-top: auto;
        @media (min-width:1500px){
          font-size:16px;
          
          margin-bottom:15px;
        }
        @media (min-width:1024px) and (max-width:1500px){
          font-size:14px;
          margin-bottom:0px;
        }
        @media  screen and (max-width: 1440px) {
          width: 80%;
        }
    }
    .card-button:hover {
      background:white !important;
      border:1.5px solid white;
      color:#9D1915;
      
    }
    .card-text{
      color:white;
      text-align:center;
      font-family: 'Montserrat', sans-serif;
      line-height:18px;
      font-size:18px;
      margin-top:2%;
      font-weight:500;
    }
    .card-title{
      text-align:center;
      margin-bottom:10px;
      color:white;
      font-weight:700;
      font-family: 'Montserrat', sans-serif;
      min-height:45px;
        @media (min-width:1500px){
          font-size:25px;
          margin-top:10px;
        }
        @media (min-width:1280px) and (max-width:1500px){
          font-size:18px;
          margin-top:0px;
        }
        @media (max-width:1280px){
          font-size:12px;
          margin-top:0px;
        }
      .dot-span{
        color: #D72B52;
      }
    }
    .card-subtitle{
      margin-bottom:0px;
      color:white;
      font-weight:700;
      font-family: 'Montserrat', sans-serif;
        @media (min-width:1500px){
          font-size:22px;
          margin-top:10px;
        }
        @media (min-width:1280px) and (max-width:1500px){
          font-size:15px;
          margin-top:0px;
        }
        @media (max-width:1280px){
          font-size:14px;
          margin-top:0px;
        }
    }
`