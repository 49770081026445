/*----- Core -----*/
import React from 'react';
import { Trans } from "react-i18next";
import { DesktopStyles, ContentVideos } from "../Styles";
import { Image, Button } from "react-bootstrap";
import { navigate } from "hookrouter";
import {renderVideos} from '../data';

import WaveImage from "../../../assets/images/onda-videos-fondo-rosa-rojo-aon.png";

const Videos = () => {
	return (
		<DesktopStyles>
			<div className="content-image">
				<Image src={WaveImage}></Image>
			</div>
			<div className="content-text">
				<div className="content-title">
					<div className="title">
						<h1><Trans>videos.self</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>videos.description</Trans></p>
				</div>
				<ContentVideos>
					{renderVideos('500px', '280px')}
				</ContentVideos>
				{/*<div className="content-button">
					<Button className="default-button" onClick={() => navigate("/contact")}>
						<Trans>videos.button</Trans>
					</Button>
	</div>*/}
			</div>        
		</DesktopStyles >
	);

}

export default (Videos)
