import styled from 'styled-components'

export const Styles = styled.div`

.my-popup-content{
	border-radius: 15px;
	//background: transparent linear-gradient(359deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box !important;
	background: transparent linear-gradient(to top, #470503 0%, #9D1915 100%) 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 3px 6px #0000004D;
	opacity: 1;
	border: none !important;
}

		
	/*full hd*/
	@media all and (min-width:1500px){
		.my-popup-content{
			border-radius: 30px;
			min-width: 25vw;
			max-width: 25vw;
			widht: 25vw;
			min-height: 41vh;
			padding: 5px;
		}
	}
	/*hd*/
	@media all and (min-width: 769px) and (max-width: 1499px) {
		.my-popup-content{
			border-radius: 15px;
			min-width: 33vw;
			max-width: 33vw;
			widht: 33vw;
			min-height: 36vh;
			padding: 5px;
		}
	}
	/*tablet*/
	@media all and (min-width: 480px) and (max-width: 768px){
		.my-popup-content{
			border-radius: 15px;
			min-width: 78vw;
			max-width: 78vw;
			widht: 78vw;
			
			padding: 5px;
		}
	}
	/*mobile*/
	@media all and (max-width: 479px) {
		.my-popup-content{
			border-radius: 15px;
			min-width: 78vw;
			max-width: 78vw;
			widht: 78vw;
			
			padding: 5px;
		}
	}

  	.my-popup-arrow {
		color: rgb(255, 255, 255);
  	}
	[role='tooltip'].my-popup-content {
		width: 200px;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  	}
  
  	.my-popup-overlay {
		background: rgba(0, 0, 0, 0.5);
  	}
  	[data-popup='tooltip'].my-popup-overlay {
		background: transparent;
  	}	
`
