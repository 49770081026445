export default function dataGenerator(lng) {
	const cards_1 = [
		{

			products_cod: ["AVG35020SR00B", "AVG35030SR00B", "AVG35040SR00B", "AVG35050SR00B", "AVG35060SR00B"],
			products_type: ["h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AVG350200000B", "AVG350300000B", "AVG350400000B", "AVG350500000B", "AVG350600000B"],
			products_type: ["h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AVG35020LA00B", "AVG35030LA00B", "AVG35040LA00B", "AVG35050LA00B", "AVG35060LA00B"],
			products_type: ["h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AVG35030SF00B", "AVG35040SF00B", "AVG35050SF00B", "AVG35060SF00B", ""],
			products_type: ["h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm", ""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AAN350000000B"],
			products_type: [""],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ATR35000IP00B"],
			products_type: ["h 12 mm"],
			products_detail: ["", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ATR35SHOIP00B"],
			products_type: ["h 9 mm"],
			products_detail: ["", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ATR35000IR00B"],
			products_type: ["h 12 mm"],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ATR35SHOIR00B"],
			products_type: ["h 9 mm"],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ATR35SLIIP00B"],
			products_type: [""],
			products_detail: ["", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ATR35SLIIR00B", ""],
			products_type: ["", ""],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ATR35LNGSN00B", "ATR35SHOSN00B"],
			products_type: lng === "es" ?
				["Largo", "Corto"] :
				["Long", "Short"],
			products_detail: ["", ""],
			information_text: "IHCprostheticComponents.screw_included"
		}
	]
	const cards_2 = [
		{
			products_cod: ["AMO00080ZRROB", "AMO00080ZRNRB", "AMO00100ZRROB", "AMO00100ZRNRB"],
			products_type: ["h 8 mm", "h 8 mm", "h 10 mm", "h 10 mm"],
			products_detail: lng === "es" ?
				["Rotatorio", "No Rotatorio", "Rotatorio", "No Rotatorio"] :
				["Engaging", "Not Engaging", "Engaging", "Not Engaging"],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35090PT00B", "AMO35130PT00B"],
			products_type: ["h 9 mm", "h 13 mm"],
			products_detail: ["", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
	]
	const cards_3 = [
		{
			products_cod: ["ALS0000500ROB", "ALS0001500ROB", "ALS0003000ROB", "ALS0000500NRB", "ALS0001500NRB", "ALS0003000NRB"],
			products_type: ["h 0.5 mm", "h 1.5 mm", "h 3.0 mm", "h 0.5 mm", "h 1.5 mm", "h 3.0 mm"],
			products_detail: lng === "es" ?
				["Rotatorio", "Rotatorio", "Rotatorio", "No Rotatorio", "No Rotatorio", "No Rotatorio"] :
				["Engaging", "Engaging", "Engaging", "Not Engaging","Not Engaging","Not Engaging"],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ALI0000500ROB", "ALI0001500ROB", "ALI0003000ROB", "ALI0000500NRB", "ALI0001500NRB", "ALI0003000NRB"],
			products_type: ["h 0.5 mm", "h 1.5 mm", "h 3.0 mm", "h 0.5 mm", "h 1.5 mm", "h 3.0 mm"],
			products_detail: lng === "es" ?
				["Rotatorio", "Rotatorio", "Rotatorio", "No Rotatorio", "No Rotatorio", "No Rotatorio"] :
				["Engaging", "Engaging", "Engaging", "Not Engaging","Not Engaging","Not Engaging"],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ALI0000000SB0", ""],
			products_type: ["", ""],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ALI00000TR000"],
			products_type: ["Normal", ""],
			products_detail: ["", ""],
			information_text: ""
		},
		{
			products_cod: ["ALI00SHOTR000"],
			products_type: lng === "es" ?
				["Corto"] :
				["Short"],
			products_detail: [""],
			information_text: ""
		}
	]
	const cards_4 = [
		{
			products_cod: ["AMO35090AV00B", "AMO35110AV00B", "AMO35130AV00B", "AMO35150AV00B", ""],
			products_type: ["h 9 mm", "h 11 mm", "h 13 mm", "h 15 mm", ""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMO35070DS00B", "AMO35090DS00B", "AMO35110DS00B", "", ""],
			products_type: ["h 7 mm", "h 9 mm", "h 11 mm", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35070ST00B", "AMO35090ST00B", "AMO35110ST00B", "", ""],
			products_type: ["h 7 mm", "h 9 mm", "h 11 mm", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35070DL00B", "AMO35090DL00B", "AMO35110DL00B", "", ""],
			products_type: ["h 7 mm", "h 9 mm", "h 11 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35010STHSB", "AMO35020STHSB", "AMO35030STHSB", "AMO35040STHSB", "AMO35050STHSB"],
			products_type: ["h 1 mm", "h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35010DLHSB", "AMO35020DLHSB", "AMO35030DLHSB", "AMO35040DLHSB", "AMO35050DLHSB"],
			products_type: ["h 1 mm", "h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35000IN10B", "AMO35000IN15B", "AMO35000IN20B", "AMO35000IN25B", "AMO35000IN30B"],
			products_type: ["10°", "15°", "20°", "25°", "30°"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO350SMIN10B", "AMO350SMIN15B", "AMO350SMIN20B", "AMO350SMIN25B", "AMO350SMIN30B"],
			products_type: ["10°", "15°", "20°", "25°", "30°"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
	]
	const cards_5 = [
		{
			products_cod: ["AMUNB010ST00B", "AMUNB020ST00B", "AMUNB030ST00B", "AMUNB040ST00B", ""],
			products_type: ["h 1 mm ", "h 2 mm", "h 3 mm", "h 4 mm", ""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMUNB020IN17B", "AMUNB030IN17B", "AMUNB030IN30B", "AMUNB040IN30B"],
			products_detail: ["17°", "17°", "30°", "30°"],
			products_type: ["h 2 mm ", "h 3 mm ", "h 3 mm ", "h 4 mm "],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMUNB030CG000", "AMUNB050CG000", ""],
			products_type: ["h 3 mm ", "h 5 mm", "", "", ""],
			products_detail: lng === "es" ?
				["(altura total 5mm)", "(altura total 7mm)", "", "", ""] :
				["(total height 5mm)", "(total height 7mm)", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMUNB000CL000", "", "", ""],
			products_type: [""],
			products_detail: [""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMUNB000PT000", "", "", ""],
			products_type: [""],
			products_detail: [""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMUNB000AD000"],
			products_type: [""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMUNB000TM000"],
			products_type: [""],
			products_detail: [""],
			information_text: "IHCprostheticComponents.screw_included"
		},
	]
	const cards_6 = [
		{
			products_cod: ["AMO00055TT00B", "AMO00060TT00B", "AMO00070TT00B", "AMO00080TT00B", "AMO00090TT00B", "AMO00100TT00B"],
			products_type: ["h 5.5 mm", "h 6 mm", "h 7 mm", "h 8 mm", "h 9 mm", "h 10 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO35SLITT05B", "AMO35SLITT10B", "AMO35SLITT15B", "", "", ""],
			products_type: ["5°", "10°", "15°", "", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["AMO00010TT05B", "AMO00010TT10B", "AMO00010TT15B", "AMO00020TT05B","AMO00020TT10B","AMO00020TT15B"],
			products_detail: ["5°", "10°", "15°","5°", "10°", "15°"],
			products_type: ["h.1 mm", "h.1 mm", "h.1 mm", "h.2 mm", "h.2 mm","h.2 mm"],
			information_text: "IHCprostheticComponents.screw_included"
		},
		{
			products_cod: ["ACM00000TTPRB"],
			products_detail: [""],
			products_type: [""],
		},
		{
			products_cod: ["ACM00000TTDEB"],
			products_detail: [""],
			products_type: [""],
		},
		{
			products_cod: ["ACM00000TTWEB"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["AAN35000TT00B"],
			products_type: [""],
			products_detail: [""],
			information_text: ""
		},
		{
			products_cod: ["ATR35000TT00B"],
			products_type: [""],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
	]
	const cards_7 = [
		{
			products_cod: ["AMO35010OVA4B", "AMO35020OVA4B", "AMO35030OVA4B", "AMO35040OVA4B", "AMO35050OVA4B", "AMO35060OVA4B", "AMO35070OVA4B"],
			products_type: ["h 1 mm", "h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm", "h 7 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMU00020IN17P", "AMU00040IN17P", "AMU00020IN30P", "AMU00040IN30P", ""],
			products_detail: ["17°", "17°", "30°", "30°", ""],
			products_type: ["h 2 mm", "h 4 mm", "h 2 mm", "h 4 mm", ""],
			information_text: ""
		},
		{
			products_cod: ["ALK350100000B", "ALK350200000B", "ALK350300000B", "ALK350400000B", "ALK350500000B", "ALK350600000B"],
			products_type: ["h 1 mm", "h 2 mm", "h 3 mm", "h 4 mm", "h 5 mm", "h 6 mm"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
	]
	const cards_8 = [
		{
			products_cod: ["AMO00025CVCS0", "AMO00025CVCH0", "AMO00025CVCN0"],
			products_type: lng === "es" ?
				["Rosa Suave", "Normal", "Duro Verde"] :
				["Soft Pink", "Normal", "Hard Green"],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMO00025CVCX0", "", ""],
			products_type: ["Stainless", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AMO00000CVDP0", "", ""],
			products_type: ["", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: ""
		},
		{
			products_cod: ["AVS000830000B", ""],
			products_type: lng === "es" ?
				["Rosca M 1.8", ""] :
				["Thread M 1.8", ""],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: ["AMUNB000VMIN0", ""],
			products_type: lng === "es" ?
				["Rosca M 1.4", "", "", "", ""] :
				["Thread M 1.4", "", "", "", ""],
			products_detail: ["", "", "", "", ""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: ["AVR000000000B"],
			products_type: lng === "es" ?
				["Largo"] :
				["Long"],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: ["AVR00SHO0000B"],
			products_type: lng === "es" ?
				["Corto"] :
				["Short"],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: ["AMUNB180VR000"],
			products_type: ["h 18 mm"],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: ["AMUNB100VR000"],
			products_type: ["h 10 mm"],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
	]
	const cards_9 = [
		{
			products_cod: [
				["ATE340180000B", "ATE340280000B", "ATE340380000B"],
				["ATE410180000B", "ATE410280000B", "ATE410380000B"],
				["ATE500180000B", "ATE500280000B", "ATE500380000B"]
			],
			products_type: [
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
			],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: [
				["ATE34025CG000", "ATE34035CG000"],
				["ATE41025CG000", "ATE41035CG000"],
				["ATE50025CG000", "ATE50035CG000"]
			],
			products_type: [
				["h. 2.5 mm", "h. 3.5 mm"],
				["h. 2.5 mm", "h. 3.5 mm"],
				["h. 2.5 mm", "h. 3.5 mm"]
			],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: ["ATE00000VF000"],
			products_type: [""],
			products_detail: [""],
			information_text: "IHCprostheticComponents.package_10"
		},
		{
			products_cod: [
				["ALS34000TERO0", "ALS34000TENR0"],
				["ALS41000TERO0", "ALS41000TENR0"],
				["ALS50000TERO0","ALS50000TENR0"]
			],
			products_type: lng === "es" ? [
				["Rotatorio","No Rotatorio"],
				["Rotatorio","No Rotatorio"],
				["Rotatorio","No Rotatorio"],
			]
				:
				[
					["Engaging","Not Engaging"],
					["Engaging","Not Engaging"],
					["Engaging","Not Engaging"],
				],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: [
				["ALI34000TERO0", "ALI34000TENR0"],
				["ALI41000TERO0", "ALI41000TENR0"],
				["ALI50000TERO0","ALI50000TENR0"]
			],
			products_type: lng === "es" ? [
				["Rotatorio","No Rotatorio"],
				["Rotatorio","No Rotatorio"],
				["Rotatorio","No Rotatorio"],
			]
				:
				[
					["Rotating","Not Rotating"],
					["Rotating","Not Rotating"],
					["Rotating","Not Rotating"],
				],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
			]
		},
	]
	const data = {
		cards_1,
		cards_2,
		cards_3,
		cards_4,
		cards_5,
		cards_6,
		cards_7,
		cards_8,
		cards_9
	}
	return data;
}