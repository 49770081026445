/*----- Core -----*/
import React from 'react'
import { Row, Col, Image } from "react-bootstrap";

/*----- Actions -----*/

/*----- Selectors -----*/

/*----- Components -----*/
import {DesktopStyles} from '../Styles'
import AboutUsBackground from "../../../assets/images/about-us-background.png";

/*----- Commons -----*/
import '../../../commons/i18n';
import { Trans } from 'react-i18next';
import RegisterMark from '../../../components/registerMark';

const About = () => {
	return (
		<DesktopStyles>
			<div className="about-us-container">
				<Row className="first-row">
					<Col className="content-col" md={6}>
						<div className="title">
							<h1><Trans>about.self</Trans></h1>
							<span className="dot"></span>
						</div>
						<div className="history-content">
							<p className="allOrNothing"><Trans>about.history</Trans></p>
							<p><Trans>about.history_body</Trans></p>
						</div>
					</Col>
				</Row>
				<Row className="second-row">
					<div className="content-col">
						<Col className="information-col" md={6}>
							<p><RegisterMark>about.mid_body</RegisterMark></p>
						</Col>
						<Col className="information-col" md={6}>
							<p><Trans>about.mid_body_2</Trans></p>
						</Col>
					</div>
				</Row>

				<div className="content-image">
					<div className="gradient" />
					<Image src={AboutUsBackground}></Image>
				</div>
			</div>
			<div className="about-us-data">
				<div className="product-card">
					<div className="title">
						<h1><Trans>about.vision</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>about.vision_body</Trans></p>
				</div>
				<div className="product-card">
					<div className="title">
						<h1><Trans>about.mission</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>about.mission_body</Trans></p>
				</div>
				<div className="product-card">
					<div className="title">
						<h1><Trans>about.values</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>about.values_body</Trans></p>
				</div>
				<div className="product-card">
					<div className="title">
						<h1><Trans>about.science</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>about.science_body</Trans></p>
				</div>
			</div>
		</DesktopStyles>
	);
}

export default About