/*----- Core -----*/
import React from 'react';
import { Trans } from "react-i18next";
import { MobileStyles } from "../Styles";
import { Image, Button } from "react-bootstrap";
import { navigate } from "hookrouter";

import WaveImage from "../../../assets/images/onda-videos-fondo-rosa-rojo-aon.png";
import { renderVideos } from '../data';

const Videos = () => (
	<MobileStyles>
		<div className="content-image">
			{
			//<Image src={WaveImage}></Image>
			}
		</div>
		<div className="content-text">
			<div className="content-title">
				<div className="title">
					<h1><Trans>videos.self</Trans></h1>
					<span className="dot"></span>
				</div>
				<p><Trans>videos.description</Trans></p>
			</div>
		</div>
		<div className="content-videos">
			{renderVideos('100%', '176px')}
		</div>
		{/*<div className="content-button">
			<Button className="default-button" onClick={() => navigate("/contact")}>
				<Trans>videos.button</Trans>
			</Button>
		</div>*/}
	</MobileStyles >
);


export default Videos;
