import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Image, Accordion } from 'react-bootstrap';
import CustomToggle from '../../../../components/accordion/CustomToggle'
import LevelOneStyles from './styles';
import { navigate } from 'hookrouter';

import AccordionDentalNavigation from '../../../../components/accordion/AccordionDentalNavigation';

import VioletWave from '../../../../assets/images/Dental/LevelOneMobile/onda-fondo-rosa-rojo-aon-mobile.png';
import systems from '../systems';

import SmartImplants from './SmartImplants';
import ProstheticComponents from './ProstheticComponents';
import SurgicalInstruments from './SurgicalInstruments';

import SurgicalGuidedContent from '../../../guidedSurgery/mobile/SurgicalGuidedContent';
import RegisterMark from '../../../../components/registerMark';

import CCNewSmartImplantsMobile from '../../../conicalConnection/mobile/smartImplants/new/index'

const LevelOne = ({ system }) => {
	const [openSmartImplants, setOpenSmartImplants] = useState(false);
	const [openProstheticComponent, setOpenProstheticComponent] = useState(false);
	const [openSurgicalInstruments, setOpenSurgicalInstruments] = useState(false);

	const renderTextSystem = () => {
		let render = [];
		systems[system].texts.forEach(text => {
			console.log(text);
			render.push(
				<p><RegisterMark>{text}</RegisterMark></p>
			)
		})
		return render;
	}

	const RenderInformationSystemsContent = () => {
		console.log("sistem. " + system)

		if (system == 'conicalConnection') {
			return;
		}
		return (
			<div className="information-system">
				<Image src={systems[system].systemImage} />
				<div className="text-content">
					{renderTextSystem()}
				</div>
			</div>
		)

	}

	const RenderImplantsAccordionContent = () => {
		if (system == 'conicalConnection') {
			return <CCNewSmartImplantsMobile style={{padding: "0 !important;"}}/>;
		}

		return (
			<Accordion >
				<div className="title">
					<CustomToggle eventKey="1" setOpen={setOpenSmartImplants} open={openSmartImplants}>
						<h1><RegisterMark>{systems[system].smartImplants.title}</RegisterMark></h1>
					</CustomToggle>
				</div>
				<Accordion.Collapse eventKey="1">
					<SmartImplants
						content={systems[system].smartImplants}
						open={openSmartImplants}
					/>
				</Accordion.Collapse>
			</Accordion>
		)
	}

	const renderSystem = () => {
		return (
			<>
				<div>
					{RenderInformationSystemsContent()}
				</div>
				<div className='systems-content' style={system == 'conicalConnection' ? {'margin': '15px !important'} : {}}>
					{RenderImplantsAccordionContent()}
					<Accordion >
						<Image className="image-violet-wave-2" src={VioletWave} />
						<div className="title">
							<CustomToggle eventKey="2" setOpen={setOpenProstheticComponent} open={openProstheticComponent}>
								<h1><Trans>{systems[system].prostethicComponents.title}</Trans></h1>
							</CustomToggle>
						</div>
						<Accordion.Collapse eventKey="2">
							<ProstheticComponents
								content={systems[system].prostethicComponents}
							/>
						</Accordion.Collapse>
					</Accordion>
					<Accordion>
						<div className="title">
							<CustomToggle eventKey="3" setOpen={setOpenSurgicalInstruments} open={openSurgicalInstruments}>
								<h1><Trans>{systems[system].surgicalInstruments.title}</Trans></h1>
							</CustomToggle>
						</div>
						<Accordion.Collapse eventKey="3">
							<SurgicalInstruments
								content={systems[system].surgicalInstruments}
							/>
						</Accordion.Collapse>
					</Accordion>
				</div>
			</>
		)
	}
	const renderUniversalExpander = () => {
		return (
			<div className="universal-expanders-content">
				<div className="text">
					<p><Trans>{systems[system].texts[0]}</Trans></p>
				</div>
				<Image src={systems[system].systemImage}></Image>
			</div>
		)
	}

	const selectSystem = () => {
		switch (system) {
			case 'conicalConnection':
				return renderSystem();
			case 'internalHexagonConnection':
				return renderSystem();
			case 'universalExpanders':
				return renderUniversalExpander();
			case 'SurgicalGuided':
				return <SurgicalGuidedContent />;
			default:
				break;
		}
	}
	const renderImages = () => {
		if (system === 'SurgicalGuided') return;
		if (system === 'conicalConnection') return;
		if (system === 'universalExpanders') {
			return (
				<Image className="image-violet-wave-3" src={VioletWave} />
			)
		} else {
			return (
				<Image className="image-violet-wave-1" src={VioletWave} />
			)
		}
	}

	const renderCatalogButton = () => {
		if (system == 'conicalConnection') {
			return;
		}

		return (
			//remove link navigation, added logiocal navigation instead
			<div className="catalog-button" onClick={() => navigate('/download')}>
				{/*<a
					className="button zoom"
					role="button"
					href="/download"
				>
					<Trans>general.download_catalog</Trans>
				</a>*/}
				<a
					className="button zoom"
					role="button"
					style={{"color": "white"}}
				>
					<Trans>general.download_catalog</Trans>
				</a>
			</div>
		)
	}

	return (
		<LevelOneStyles id="level-one" openSmartImplants={openSmartImplants}>
			<div className={system == 'conicalConnection' ? "" : "information-content"}>
			
				<div className="title-content">
					<h1><Trans>dental.self</Trans></h1>
					<span className="dot"></span>
				</div>
				<div className="accordion-content">
					<AccordionDentalNavigation
						systems={systems}
						system={system}
					/>
				</div>
				{selectSystem()}
				{renderCatalogButton()}

			</div>
			{renderImages()}
		</LevelOneStyles >
	)
}

export default LevelOne;