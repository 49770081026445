import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Styles } from "./DownloadModalStyles"
import { navigate } from "hookrouter";
import DownloadForm from "./components/DownloadForm";

const DownloadModal = (props) => {

	const onSubmit = () => {
		props.onButtonClose(props.currentModal);
		
	}

	const onCancel = () => {
		props.onButtonClose(props.currentModal)
		navigate('/');
	}

	return (
		<Styles>
			<Container className="modal-container" fluid>
				<DownloadForm
					onCancel={onCancel}
					onSubmit={onSubmit}
				></DownloadForm>
				{/*<Row className="buttons-row justify-content-center">
					<button className="cancel-button  modal-button" onClick={() => props.onButtonClose(props.currentModal)}
					>{props.cancelBtnMsg}</button>
				</Row>
				<Row className="buttons-row justify-content-center">
						
						<button className="confirm-button modal-button" onClick={() => {
							props.onButtonClose(props.currentModal);
							navigate('/');
						}}>
							{props.confirmBtnMsg}
						</button>
					</Row>*/}
				
			</Container>
		</Styles>
	)
}
export default DownloadModal;