// Core
import React from 'react'
import NewsGridContainer from './NewsGridContainer';
import { DesktopStyles } from "../Styles";
import { Image } from "react-bootstrap";
import { Trans } from "react-i18next"

import VioletVawe from "../../../assets/images/onda-fondo-rosa-rojo-aon.png"
const News = ({ match }) => {
	return (
		<DesktopStyles>
			<div className="content-image">
				<Image src={VioletVawe}></Image>
			</div>
			<div className="content-page">
				<div className="content-title">
					<div className="title">
						<h1><Trans>news.self</Trans></h1>
						<span className="dot"></span>
					</div>
					<p><Trans>news.description</Trans></p>
				</div>
				<NewsGridContainer match={match} />
			</div>
		</DesktopStyles>
	)
}
export default News
