import styled from 'styled-components'

export const Styles = styled.div`
	.modal-container{

	}
	.container-button{
		justify-content: center;
	}
	h1{
        text-align: center;
        width: 100%;
        font: normal normal bold 18px/20px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
		padding-top: 5%;
	}
	.main-btn{
        border-radius: 15px;
		border: 1px solid white !important;
        background: transparent 0% 0% no-repeat padding-box;
		width: 20%;
		margin-right: 5%;
		text-align: center
        font: normal normal bold 13px/19px Montserrat;
        letter-spacing: 0px;
        border: none;
    }
	.main-btn:hover{
        background: white 0% 0% no-repeat padding-box;
		border:1px solid black !important;
		color:black !important;
		transform: scale(1.03);
	}
	.form-message{
		margin-bottom: 2%;
		color: #fff;
	}
	.close-row{
		margin-top: 4%;
		max-height: 8%;
	}

	.title{
		text-align: center;
		font: normal normal bold 25px/38px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}

	.title-row{
		margin-bottom: 8px;
	}
	.buttons-row{
		margin-top: 18px;
	}

	.description{
		text-align: center;
		font: normal normal medium 18px/22px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
		margin: 0 6% 0 6%;
	}

	.close{
		background: transparent !important;
		color: white !important;
		margin-right: 3%;
		margin-top 1%;
	}

	modal-button{
		text-align: center;
		font: normal normal bold 14px/25px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}

	button{
		background-color:transparent;
		color:white;
	}

	.confirm-button{
		font-weight:700;
		height: 2.5em;
		margin-top: 6%;
		border-radius:30px;
		border: 1px solid white;
		width: 70%;
	}

	.cancel-button{
		font-weight:700;
		height: 2.5em;
		border: none;
		width: 70%;
		margin-bottom: 6%;
	}
	
	
	/*full hd*/
	@media (min-width:1367px){
		.title{
			font-size: 25px;
			margin-top: 4%;
		}

		.description{
			font-size: 20px;
			margin-left: 16%;
			margin-right: 16%;
		}

		.modal-button{
			font-size: 15px;
		}
	}
	/*hd*/
	@media (min-width: 769px) and (max-width: 1366px) {
		.title{
			font-size: 22px;
		}

		.description{
			font-size: 15px;
			margin-left: 8%;
			margin-right: 8%;
		}
		.modal-button{
			font-size: 12px;
		}
	}
	/*tablet*/
	@media (min-width: 480px) and (max-width: 768px){
		.title{
			font-size: 16px;
		}
		
		.description{
			font-size: 13px;
		}
		.modal-button{
			font-size: 10px;
		}
		.confirm-button{
			margin-top: 4%;
		}
		.cancel-button{
			margin-bottom: 4%;
		}
	}
	/*mobile*/
	@media (max-width: 479px) {
		.title{
			font-size: 12px;
		}
		.main-btn{
			border-radius: 15px;
			border: 1px solid white !important;
			background: transparent 0% 0% no-repeat padding-box;
			width: 30%;
			margin-right: 5%;
			text-align: center
			font: normal normal bold 12px/19px Montserrat;
			letter-spacing: 0px;
			border: none;
		}
		.form-message{
			margin-bottom: 2%;
			color: #fff;
			font-size: 11px;
		}
		.form-control{
			font-size: 0.9rem;
		}
	}
`
