import styled from "styled-components";
import { Styles } from '../desktop/Styles';
import AboutUsHome from "../../../assets/images/Home/abous-us-image-mobile.png";
import FaicoConnect from "../../../assets/images/Home/faico-connect-mobile.png"

export const HomeMobileStyles = styled(Styles)`
  width:100%;
  height:100%;
  position:relative;
  .carousel{
    .carousel-image{
      width:100%;
      height:88vh;
      margin:0;
      padding:0;
    }
    ol{
      bottom:0;
      left:0;
      margin-bottom:50px;
    }
    ol li{
      width: 20px;
      height: 23px;
      background: #6A5BC8;
    }
    .li-active{
      background: #49D3EA;
    }
    .carousel-text-mobile{
      width:100%;
      position:absolute;
      height:100%;
      display:flex;
      justify-content:center;
      flex-direction:column;
      padding:48px;
      p{
        font-weight:300;
        margin:0;
        padding:0;
        color:white;
      }
      .first-text{
        margin:0px;
        padding:0;
        font-size:20px;
      }
      h1{
        font-weight:700;
        color:white;
        font-size:32px;
        margin:15px 0px;;
      }
      .second-text{
        font-size:15px;
      }
      .default-button{
        background-color:#00AEFF;
        padding:10px 0 10px 0;
        height:100%;
        font-weight:700;
        font-size:15px;
        margin-top:10px;
        text-transform:none;
      }
    }
    .slogan{
      padding:48px 12px 48px 30px;
      h1{
        margin:0px;
      }
    }
  }
  .who-we-are-container-mobile{
  background-image:url('${AboutUsHome}');
  background-size:cover;
  background-repeat:no-repeat;
  background-position:bottom;
  width:100%;
  position:relative;
  height:541px;
    .content-image{
      width:100%;
      height:100%;
      position:absolute;
      background-color:rgba(0,0,0,0.4);
      /*.linear-effect{
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(135deg, rgba(0,0,0,0.4), transparent 13px);
        position: absolute;
        opacity: 0.26;*/
      }
    }
    .main-row {
      z-index:100;
      position:absolute;
      width:100%;
      height:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      .information-content{
        width:100%;
        display:flex;
        justify-content:flex-start;
        flex-direction:column;
        padding:0px 24px;
        h1{
          font-weight: 700;
          color: white;
          font-size: 30px;
          line-height:25px;
        }
        h2{
          color: white;
          font-size: 16px;
          line-height:19px;
          text-align:left;
          margin-top: 5px;
          width:296px;
          height:114px;
        }
        .default-button{
          font-family: 'Montserrat',sans-serif;
          font-size: 15px;
          font-weight: 600;
          margin-top:33px;
          display: flex;
          text-align: center;
          border-radius: 29px;
          align-items: center;
          justify-content: center;
          border: none;
          background: #00AEFF;
          width:134px;
          height:43px;
        }
        .zoomButton {
          transition: transform .2s; /* Animation */
        }
        .zoomButton:hover {
          transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        }
      }
    }
  }
  .faico-dental-cranio-content{
    width:100%;
    padding-bottom:54px;
    background-color:#171717;
    padding:80px 24px 28px 24px;
    #card-1{
      width: 100%;
      height: 339px;
      .information-content{
        width: 328px;
        height: 269px;
      }
    }
    #card-2{
      width: 100%;
      height: 361px;
      margin-top:80px;
      .information-content{
        width: 328px;
        height: 339px;
      }
    }
    .card-system-content{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .img-content{
        width: 127%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: -41px;
        left: -55px;
        img{
          width:100%;
        }
      }
      .img-content-blur{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index:30;
        filter: blur(15px);
          img{
          width:100%;
        }
      }
      .information-content {
        position: relative;
        z-index: 50;
        background: inherit;
        overflow: hidden;
      }
      .information-content{
        padding:20px 15px;
        border-radius:30px;
        position:absolute;
        h2{
          color:#00AEFF;
          font-size:13px;
          line-height:12px;
          font-weight:700;
          margin:0;
        }
        h1{
          color:white;
          font-size:25px;
          line-height:40px;
          font-weight:700;
          margin:0px;
        }
        p{
          color:white;
          margin:5px 0px 0px 0px;
          font-size:16px;
          line-height:18px;
          font-size:400;
        }
        .default-button{
          font-family: 'Montserrat',sans-serif;
          font-size: 15px;
          margin-top:15px;
          font-weight: 700;
          display: flex;
          text-align: center;
          border-radius: 29px;
          align-items: center;
          justify-content: center;
          border: none;
          background: #00AEFF;
          width:134px;
          height:43px;
        }
      }

    }
  }
  .card-system-content:first-child{
    .img-content{
      width: 107%;
      height: 100%;
      position: absolute;
      z-index: 10;
      top: -44px;
      left: -23px;
      img{
        width:100%;
      }
    }
  }
  .faico-connect-container-mobile{
    background-image:url('${FaicoConnect}');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:bottom;
    width:100%;
    position:relative;
    height:541px;
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
      .content-image{
        width:100%;
        height:100%;
        position:absolute;
        background:linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7) 99%);;
        /*.linear-effect{
          width: 100%;
          height: 100%;
          background: repeating-linear-gradient(135deg, rgba(0,0,0,0.4), transparent 13px);
          position: absolute;
          opacity: 0.26;*/
        }
      }
      .main-row {
      z-index:100;
      position:absolute;
      display:flex;
      justify-content:center;
      align-items:center;
      .information-content{
        width:100%;
        display:flex;
        justify-content:flex-start;
        flex-direction:column;
        padding:0px 24px;
        h1{
          font-weight: 700;
          color: white;
          font-size: 30px;
          line-height:32px;
          margin-top:10px;
        }
        h2{
          color: #D72B5B;
          font-size: 16px;
          line-height:19px;
          text-align:left;
          margin:0px;
          font-weight: 700;
        }
        h3{
          color:white;
          font-size:14px;
          font-weight: 500;
        }
        .default-button {
          font-family: 'Montserrat',sans-serif;
          font-size: 14px;
          font-weight: 700;
          color: black;
          display: flex;
          text-align: center;
          border-radius: 50px;
          border: 3px solid #FFFFFF;
          align-items: center;
          justify-content: center;
          background: white;
          width: 214px;
          height: 42px;
          margin-bottom: 37px;
          margin-top: 17px;
        }
        .zoomButton {
          transition: transform .2s; /* Animation */
        }
        .zoomButton:hover {
          transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        }
      }
    }
  }
  .faico360-container{
    height:509px;
    padding:69px 24px 10px 24px;
    .card-faico360-connect{
      position:relative;
      .refresh-content{
        margin:0;
        padding:0;
        width:100%;
        height:100%;
        position: absolute;
        .refresh-image{
          width:100%;
          height:100%;
          bottom:0;
          margin:0;
        }
      }
      .content{
        margin:0;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        h1{
          color:white;
          font-size:30px;
          line-height:37px;
          font-weight:700;
          margin:0;
        }
        .body{
          color:white;
          font-size:16px;
          margin:24px 0px 0px 0px;
          line-height:19px;
          height:103px;
        }
        .arrow-list{
          margin-top:10px;
          width:100%;
          .arrow{
            display:flex;
            justify-content:flex-start;
            align-items:center;
            img{
              width:8px;
              height:13px;
            }
            p{
              font-size:16px;
              font-weight:700;
              color:white;
              margin:0 10px;
            }
          }
        }
        .button-connect{
          margin:100px 0px 0px 0px;
          z-index:100;
        }
      }
    }
  }

  .aon-nav-button{
    width: 100%;
  }

  #aon-nav-button{
    width: 100%;
  }

  .imageLogoFaico{
    position: absolute;
    top: 6%;
    left: 12%;
    z-indez: 8900;
    
  }
  .imageLogoAon{
    position: absolute;
    top: 6%;
    left: 50%;
    z-indez: 8900;
  }


`