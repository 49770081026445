import React from 'react'
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import { navigate } from 'hookrouter';
import Styles from "./Styles.js";
import { Trans } from "react-i18next";
import i18n from '../../../../../commons/i18n'
//images
import Imagen1 from '../../../../../assets/images/Implants/Imagen-1.png';
import FVconLogo from '../../../../../assets/images/Implants/Logo REVCON_Rojo-Blanco.png';
import section2Image from '../../../../../assets/images/Implants/03_FVCON The_Connection.png';
import section2Background from '../../../../../assets/images/Implants/onda-fondo-rosa-rojo-aon.png';

//section3
import section3_img1 from '../../../../../assets/images/Implants/04_FVCON_Conical.png';
import section3_img2 from '../../../../../assets/images/Implants/05_FVCON__Platform_Switching.png';
import section3_img3 from '../../../../../assets/images/Implants/06_FVCON_Unique_Platform.png';
import section3_img4 from '../../../../../assets/images/Implants/07_FVCON_1i1a.png';
import section3_img5 from '../../../../../assets/images/Implants/08_FVCON_Carga_Inmed.png';
import section3_img6 from '../../../../../assets/images/Implants/09_FVCON_Protocols.png';

//section4
import section4_isFour from '../../../../../assets/images/Implants/10_FVCON_Is-Four.png';
import section4_cyroth from '../../../../../assets/images/Implants/11_FVCON_Cyroth.png';

//import SmartImplantsModal from "../../smartImplantsModal/index";
//import { Modal } from "../../../../../components/modals/Modal.js";

//section5
import section5_leftImg from '../../../../../assets/images/Implants/Imagen_2.png';
import section5_rightImage from '../../../../../assets/images/Implants/Imagen_3.png';

//section6
import section6_Img1 from '../../../../../assets/images/Implants/15_FVCON_Prostetic.png';
import section6_Img2 from '../../../../../assets/images/Implants/19_FVCON_(opcional).png';
import section6_Img3 from '../../../../../assets/images/Implants/21_FVCON_KiSS.png';
import section6_Img4 from '../../../../../assets/images/Implants/23_FVCON_Transepithelial.png';
import section6_usa from '../../../../../assets/images/Implants/Available-in-usa.png';
import section6_eeuu from '../../../../../assets/images/Implants/disponible-america.png';

import section6_onetoone from '../../../../../assets/images/Implants/Logo_One-to-One.png';
import section6_kiss from '../../../../../assets/images/Implants/Logo_KiSS.png';


//section 7
import section7_unique from '../../../../../assets/images/Implants/unique.png';
import section7_performing from '../../../../../assets/images/Implants/performing.png';
import section7_safe from '../../../../../assets/images/Implants/safe.png';
import section7_unico from '../../../../../assets/images/Implants/unico.png';
import section7_performante from '../../../../../assets/images/Implants/performante.png';
import section7_seguro from '../../../../../assets/images/Implants/seguro.png';

//section 8
import section8_cover from '../../../../../assets/images/Implants/brochure-revcon-presentacion.png';


import VioletWaveImg from "../../../../../assets/images/Implants/onda-fondo-rosa-rojo-aon-1.png";

import RegisterMark from '../../../../../components/registerMark/index.js';
import RegisterMarkBold from '../../../../../components/registerMarkBold/index.js';

import { BUCKET_URL } from '../../../../../services/BaseUrl'
//const ModalContainer = Modal(SmartImplantsModal)

const englishCatalog = '/pdf/Ingles/1_Dental_Catalog_And_Documents/AoN_Implants_REVCON_Morse_Cone_Connection_2021_(EN).pdf';
const spanishCatalog = '/pdf/Español/1_CatalogosDentalesyDocumentos/AoN_Implants_Conexión_Cono_Morse_REVCON_2021_(SP).pdf';

class CCNewSmartImplantsMobile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isModal: false,
			actualModal: 0
		};

	}

	getImageUniqueSection7() {
		if (i18n.language == 'en') {
			return (<Image src={section7_unique} style={{ width: '100%' }}></Image>)
		} else {
			return (<Image src={section7_unico} style={{ width: '100%' }}></Image>)
		}
	}

	getImagePerformingSection7() {
		if (i18n.language == 'en') {
			return (<Image src={section7_performing} style={{ width: '100%' }}></Image>)
		} else {
			return (<Image src={section7_performante} style={{ width: '100%' }}></Image>)
		}
	}

	getImageSafeSection7() {
		if (i18n.language == 'en') {
			return (<Image src={section7_safe} style={{ width: '100%' }}></Image>)
		} else {
			return (<Image src={section7_seguro} style={{ width: '100%' }}></Image>)
		}
	}

	getImageAvaible() {

		if (i18n.language == 'en') {
			return (<Image src={section6_usa} className="bg-img3-section6"></Image>)
		}

		return (<Image src={section6_eeuu} className="bg-img3-section6"></Image>)
	}

	render() {
		return (
			<Styles>
				<div className="section1 ">
					<div className="black-bg-gradient"></div>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={1}></Col>
						<Col lg={10}>
							<Row className="d-flex justify-content-center align-content-center">
								<Col lg={5}></Col>
								<Col lg={2}>
									<Row className="d-flex justify-content-center align-content-center mt-5">
										<Image src={Imagen1} className="img-fluid section1-main-img"></Image>
									</Row>
									<br></br>
								</Col>
								<Col lg={5}></Col>
								<Image src={FVconLogo} className="img-fluid logo-img"></Image>
							</Row>
							<br></br>
						</Col>
						<Col lg={1}></Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<h3 className="title-section1"><Trans>CCsmartImplants.FVconPage.section1.title</Trans></h3><br></br>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<h3 className="bold-title-section1"><Trans>CCsmartImplants.FVconPage.section1.subtitle</Trans></h3>
					</Row>
					<br></br>
					<br></br>
				</div>
				<div className="section2">
					<Image src={section2Background} className="bg-img section2-background"></Image>

					<Row className="d-flex justify-content-center align-content-center mt-5 pt-5">

						<Col lg={5} sm={10} xs={10}>
							<p className="text1-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text1</RegisterMark></p>
						</Col>

					</Row>

					<Row className="d-flex justify-content-center align-content-center mt-5 pt-5">
						<Col lg={5} sm={10} xs={10}>
							<p className="title1-section2"><Trans>CCsmartImplants.FVconPage.section2.subTitle1</Trans></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<p className="text-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text2</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<p className="text-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text3</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<Row className="d-flex justify-content-center align-content-center">
								<Col lg={6}>

									<p className="brand-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.subTitle2</RegisterMark></p>
									<p className="brand2-section2"><Trans>CCsmartImplants.FVconPage.section2.subTitle3</Trans></p>
									<p className="footer-section2"><Trans>CCsmartImplants.FVconPage.section2.text4</Trans></p>
								</Col>
								<Col lg={6} className="mx-5 mt-5">
									<Image src={section2Image} className="bg-img-section2"></Image>
								</Col>
							</Row>

						</Col>
					</Row>

				</div>
				<div className="section3">
					<Image src={VioletWaveImg} className="bg-img section3-background"></Image>

					<Row className="d-flex justify-content-center align-content-center pt-5">
						<Col lg={5} sm={10} xs={10}>
							<p className="mb-5">
								<span className="title-section3"><RegisterMark>CCsmartImplants.FVconPage.section3.title1</RegisterMark></span>
								<span className="title-connector-section3"><Trans>CCsmartImplants.FVconPage.section3.title11</Trans></span>
								<span className="title-section3"><Trans>CCsmartImplants.FVconPage.section3.title12</Trans></span>
							</p>
						</Col>
					</Row>

					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img1} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4 mb-5"><RegisterMark>CCsmartImplants.FVconPage.section3.text1</RegisterMark></p>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img2} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4 mb-5"><Trans>CCsmartImplants.FVconPage.section3.text2</Trans></p>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img3} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4"><Trans>CCsmartImplants.FVconPage.section3.text3</Trans></p>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img4} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4 mb-5"><Trans>CCsmartImplants.FVconPage.section3.text4</Trans></p>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center mb-4">
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img5} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4 mb-5"><Trans>CCsmartImplants.FVconPage.section3.text5</Trans></p>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Image src={section3_img6} className="bg-img-section3"></Image>
							<p className="texts-section3 mt-4 mb-5"><Trans>CCsmartImplants.FVconPage.section3.text6</Trans></p>
						</Col>
					</Row>
				</div>
				<div className="section4">

					<Row className="d-flex justify-content-center align-content-center pt-5">
						<Col lg={5} sm={10} xs={10}>
							<h2 className="title-scetion4 mt-5"><RegisterMark>CCsmartImplants.FVconPage.section4.title</RegisterMark></h2>
						</Col>
					</Row>

					<Row className="d-flex justify-content-center align-content-center mt-4">
						<Col lg={5} sm={10} xs={10}>
							<h2 className="title-is_four"><RegisterMark>CCsmartImplants.FVconPage.section4.title_isFour</RegisterMark></h2>
							<p className="text-scetion4"><Trans>CCsmartImplants.FVconPage.section4.text_isFour</Trans></p>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Row className="d-flex justify-content-center align-content-center my-5">
								<Image src={section4_isFour} className="bg-img-section4"></Image>
								<div className="plus-button-section4" onClick={() => { navigate('/dental/conicalConnection/smartImplants/isFour') }}><div className="plus-section4"></div></div>
							</Row>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<h2 className="title-cyroth"><RegisterMark>CCsmartImplants.FVconPage.section4.title_cyroth</RegisterMark></h2>
							<p className="text-scetion4"><Trans>CCsmartImplants.FVconPage.section4.text_cyroth</Trans></p>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Row className="d-flex justify-content-center align-content-center my-5">
								<Image src={section4_cyroth} className="bg-img-section4"></Image>
								<div className="plus-button-section4" onClick={() => { navigate('/dental/conicalConnection/smartImplants/cyroth') }}><div className="plus-section4"></div></div>
							</Row>
						</Col>
					</Row>
					{/*<ModalContainer
						classNamePopUp={"pop-up-style"}
						onButtonClose={this.openModal}
						modal={this.state.actualModal}
						open={this.state.isModal}
					/>*/}
				</div>
				<div className="section5">
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<h2 className="title-section5"><Trans>CCsmartImplants.FVconPage.section5.title1</Trans></h2>
							<h2 className="subtitle-section5"><Trans>CCsmartImplants.FVconPage.section5.subTitle1</Trans></h2>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<p className="text-section5"><RegisterMark>CCsmartImplants.FVconPage.section5.text1</RegisterMark></p>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center mt-5">
						<Col lg={5}>
							<Image src={section5_leftImg} className="bg-img-section5"></Image>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5} sm={10} xs={10}>
							<Row className="justify-content-center align-content-center">
								<Col lg={12}>
									<h2 className="title-section5"><RegisterMark>CCsmartImplants.FVconPage.section5.title2</RegisterMark></h2>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text2</Trans></p>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text3</Trans></p>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text4</Trans></p>
								</Col>
							</Row>
							<Row className="justify-content-center align-content-center my-5">
								<Col lg={5}>
									<Image src={section5_rightImage} className="bg-img2-section5"></Image>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div className="section6">
					<Row className="d-flex justify-content-center align-content-center section6-subsection">
						<Col lg={5} sm={10} xs={10}>
							<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title1</Trans></h2>
							<div className="paragraph-section6">
								<div className="text-section6">{<Trans>CCsmartImplants.FVconPage.section6.text1</Trans>}</div>
								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text2</Trans></div>
								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text3</Trans></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text4</RegisterMark></div>
							</div>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Row  className="justify-content-center align-content-center">
								<Image src={section6_Img1} className="bg-img-section6"></Image>
							</Row>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center section6-subsection background-section6">
						<Col lg={5} sm={10} xs={10}>
							<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title2</Trans></h2>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Row  className="justify-content-center align-content-center">
								<Image src={section6_Img2} className="bg-img2-section6"></Image>
							</Row>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<div className="paragraph-section6 mb-5">
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text5</RegisterMark></div>
								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text6</Trans></div>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center align-content-center mt-5">
						<Col lg={5} sm={10} xs={10}>
							<Row className="justify-content-start align-content-start ml-2">
								<Col>
									<Image src={section6_kiss} className="bg-img-kiss-section6"></Image>
									<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title3</Trans></h2>
								</Col>
							</Row>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<div className="paragraph-section6">
								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text7</Trans></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text8</RegisterMark></div>
							</div>
						</Col>
						{/*<Col lg={5} sm={12} xs={12}>
							<Row  className="justify-content-center align-content-center">
								{this.getImageAvaible()}							
							</Row>
				</Col>*/}
						<Col lg={5} sm={12} xs={12}>
							<Row  className="justify-content-center align-content-center mt-5">
								<Image src={section6_Img3} className="bg-img4-section6"></Image>
							</Row>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center section6-subsection background-section6">
						<Col lg={5} sm={10} xs={10}>
							<Row className="justify-content-start align-content-start ml-2">
								<Col>
									<Image src={section6_onetoone} className="bg-img5-section6"></Image>
									<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title4</Trans></h2>
									<h3 className="subtitle4-section6"><Trans>CCsmartImplants.FVconPage.section6.subTitle4</Trans></h3>
								</Col>
							</Row>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<div className="paragraph-section6">
								<div className="text-section6">
									<Trans>CCsmartImplants.FVconPage.section6.text9</Trans>
									<RegisterMarkBold>CCsmartImplants.FVconPage.section6.text91</RegisterMarkBold>
									<Trans>CCsmartImplants.FVconPage.section6.text92</Trans>
								</div>

								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text10</Trans></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text11</RegisterMark></div>
							</div>
						</Col>
						{/*<Col lg={5} sm={12} xs={12}>
							<Row  className="justify-content-center align-content-center">
								{this.getImageAvaible()}
							</Row>
				</Col>*/}
						<Col lg={5} sm={12} xs={12} className="d-flex justify-content-center align-content-center">
							<Row  className="justify-content-center align-content-center mt-5">
								<Image src={section6_Img4} className="bg-img6-section6"></Image>
							</Row>
						</Col>
						
					</Row>
				</div>
				<div className="section7">
					<Row className="d-flex justify-content-center align-content-center section7-subsection">
						<Col lg={5} sm={10} xs={10}>
							<Row className="justify-content-center align-content-center">
								<Col lg={5} sm={10} xs={10}>
									{this.getImageUniqueSection7()}
									<p className="section7-text"><RegisterMark>CCsmartImplants.FVconPage.section7.text1</RegisterMark></p>
								</Col>
								<Col lg={5} sm={10} xs={10}>
									{this.getImagePerformingSection7()}
									<p className="section7-text"><Trans>CCsmartImplants.FVconPage.section7.text2</Trans></p>
								</Col>
								<Col lg={5} sm={10} xs={10}>
									{this.getImageSafeSection7()}
									<p className="section7-text"><Trans>CCsmartImplants.FVconPage.section7.text3</Trans></p>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div className="section8">
					<Row className="d-flex justify-content-center align-content-center">
						<Row className="d-flex justify-content-center align-content-center">
							<Col>
								<Image src={section8_cover} className=" img-fluid"></Image>
							</Col>
						</Row>
						<Col lg={5} sm={10} xs={10}>
							<Row className="d-flex justify-content-start align-content-start my-5">
								<h2 className="section8-title"><RegisterMark>CCsmartImplants.FVconPage.section8.title</RegisterMark></h2>
								<p className="section8-text"><Trans>CCsmartImplants.FVconPage.section8.text</Trans></p>
							</Row>
						</Col>
						<Col lg={5} sm={10} xs={10}>
							<Row className="d-flex justify-content-center align-content-center mb-5">
								<a download target="_blank" href={`${BUCKET_URL}${i18n.language === 'es' ? spanishCatalog : englishCatalog}`}>
									<Button className="section8-button"><RegisterMark>CCsmartImplants.FVconPage.section8.button</RegisterMark></Button>
								</a>
							</Row>
						</Col>
					</Row>

				</div>


			</Styles>
		);
	}
}

export default (CCNewSmartImplantsMobile)