
import React from "react";
import {BrowserView,TabletView,MobileOnlyView} from "react-device-detect";

import ViewsContainerDesktop from './ViewsContainerDesktop';
import ViewsContainerMobile from './ViewsContainerMobile';

const ViewsContainer = () => {
	return (
		<>

			{/*<BrowserView>
				<ViewsContainerDesktop />
			</BrowserView>
			<TabletView>
				<ViewsContainerMobile />
			</TabletView>
			<MobileOnlyView>
				<ViewsContainerMobile />
			</MobileOnlyView>*/
			(navigator.userAgent.match(/Windows/i)) ? (<ViewsContainerDesktop />) : (<ViewsContainerMobile />)}
		</>
	)

}
export default ViewsContainer;