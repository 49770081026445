import styled from "styled-components";

export const Styles = styled.div`
	position:relative;
  .container-page{
		background:#1C1C1C;
		padding:2%;
		z-index:100;
		.title{
			font-weight:700;
        margin:0;
        font-family: 'Montserrat', sans-serif;
        color:white;
        text-align:center;
			z-index:100;
		}
    .row-content{
      h1{
				font-size:32px;
        font-weight: 700;
        margin:0;
        font-family: 'Montserrat', sans-serif;
        color:white;
        border-bottom: 2px solid #545454;
				position:relative;
				z-index:100;
				margin-top:10px;
      }
      .content-cards{

        margin-top:3%;
				align-items:flex-end;
      }
    }
	}
	.content-buttons{
		display:flex;
		width:100%;
		justify-content:center;
		padding-top:20px;
		z-index:15;
		button{
			background: transparent !important;
			width: 186px;
			height: 41px;
			z-index:15;
			color: white;
			border: 2.5px solid white;
			font-size:12px;
			font-weight:700;
			border-radius: 25px !important;
			font-family: 'Montserrat',sans-serif;
			padding:10px;
			margin:0 10%;
		}
		button:hover {
				background:white !important;
				border:1px solid black !important;
				color:black !important;
				transform: scale(1.03);
		}
		button:active{
				border: 1px solid white;
				box-shadow:none !important;
		}
		button:focus{
				border: 1px solid white;
				box-shadow:none !important;
		}
	}
	.content-images-wave{
    position:absolute;
    width:100%;
    .image-wave-reverse{
      position:relative;
      z-index: 1;
      left: -13%;
      transform: scaleX(-1);
    }
    .image-wave{
      position:relative;
      z-index: 1;
      z-index: 10;
      right: -10%;
    }
  }
	.zIndex100{
		z-index:100 !important;
		margin-top:20px;
	}
`

export const ProductCardStyles = styled.div`
	z-index:100;
  .card-content{
    background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 50%);
    border-radius:5px;
    padding:10px;
    padding-bottom:15px;
		z-index:100;
		.content-image{
			margin-right:auto;
			margin-left:auto;
			margin-top:5%;
			margin-bottom:5%;
			z-index:100;
		}
		.content-image-type-4{
			margin-right:auto;
			margin-left:auto;
			margin-bottom:5%;
			margin-top:5%;
			padding:0 3% 0 0;
			width:fit-content;
			z-index:100;
		}
  }
  .card-content-doble{
		margin:10px;
		padding-bottom:0px;
		width:50%;
		z-index:100;
	}
	.cards-content-doble{
		background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 50%);
		border-radius:5px;
		display:flex;
		flex-direction:column;
		padding:12px;
		z-index:100;
		.content-image{
			margin-right:auto;
			margin-left:auto;
			margin-top:5%;
			margin-bottom:5%;
			z-index:100;
		}
	}
  .card-content-info{
		display:flex;
		justify-content:space-between;
		z-index:100;
	}
	.card-image{
		position:relative;
		display:flex;
		flex-direction:row;
		margin:auto;
	}
	.card-title{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight: 700;
		z-index:100;
	}
	.card-subtitle{
		color:white;
		font-family: 'Montserrat', sans-serif;
		font-size:0.7vw;
		font-weight:700;
		margin-bottom:10px;
		z-index:100;
	}
	.card-text{
		grid-template-columns: 2.5fr 1fr;
		display: grid;
		/* display:flex; */
		justify-content:space-between;
		font-size:0.7vw;
		border-bottom:1px solid #D6D6D6;
		z-index:100;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			/* margin-bottom: 0; */
			letter-spacing:0;
			z-index:100;
		}
	}
	.card-text-empty{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			letter-spacing:0;
			z-index:100;
		}
	}
	.card-text-detail{
		display:flex;
		justify-content:space-between;
		font-size:0.7vw;
		z-index:100;
		p{
			display:inline;
			margin:0;
			color:#D6D6D6;
			letter-spacing:0;
			z-index:100;
		}
	}
	.table-type-4{
		white-space:nowrap;
		tr{
			border-bottom:none;
			border-top: 1px solid #dee2e6;
		}
		tr td{
			border:0;
		}
	}
	.table-content{
		margin:0;
		padding:0;
		font-size:0.7vw;
		color:#D6D6D6;
		z-index:100;
		width: auto;
		flex: 1;
		td{
			margin:0;
			padding:0;
			z-index:100;
			border-top:none;
			border-bottom: 1px solid #dee2e6;
		}
		.last{
			text-align:right
		}
		thead{
			border:none;
			tr{
				border:none;
				z-index:100;
			}
			td{
				border:none;
				text-align:left;
				z-index:100;
			}
		}
		.td-header{
			text-align:left;
			font-weight:700;
		}
	}
	.information-text{
		color:#D6D6D6;
		text-align: right;
    margin: 10px 0px 0px 0px;
    font-size: 14px;
		z-index:100;
	}

	.product-detail{
		display:inline;
		margin:0;
		color:#D6D6D6;
		letter-spacing:0;
		font-size:10px;
		z-index:100;
	}
	img{
		z-index:100;
	}
	h1{
		z-index:100;
	}
	h2{
		z-index:100;
	}
	h3{
		z-index:100;
	}
`
export const SurgicalKitStyles = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	#img-kit{
		z-index: 100;
	}
	background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 85%);
	border-radius:5px;
	width:100%;
	margin-top:5%;
	div::-webkit-scrollbar {
		width: 5px;
		height:5px;
		background:#5F5F5F;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #909090;
		width:95%;
	}
	.content-image{
		display:flex;
		justify-content:center;
		align-items:center;
		padding-top:5%;
		width:100%;

	}
	.content-tables{
		width:100%;
		display:flex;
		margin:auto;
		.content-table{
			width:100%;
			margin:0px 1% !important;
			padding:0px !important;
			.titles{
				h3{
					color:white;
					font-weight:700;
					text-align:left;
					font-size:20px;
					margin:0;
					font-family: 'Montserrat', sans-serif;
				}
				h4{
					color:#EBEBEB;
					font-size:15px;
					text-align:left;
					color:#909090;
					margin:0;
					font-family: 'Montserrat', sans-serif;
				}
			}
			.table-scroll{
				overflow-y:auto;
				overflow-x:hidden;
				border-radius:5px;
				display:flex;
				text-align:center;
				height:816px;
			}

			table{
				border:0;
				margin:0;
				thead{
					background: #4A4949;
					border-radius:5px;
					box-shadow: 0px 2px 4px black;
					tr{
						font-size:16px;
						font-family: 'Montserrat', sans-serif;
						border:0px;
					}
					th{
						padding: 10px;
						font-weight:600;
						background:#4A4949;
						text-align: center;
						border:0px;
						vertical-align: middle;
						position: sticky;
						top: 0;
						color:white;
						font-family: 'Montserrat', sans-serif;
					}
					th:nth-child(1n){
						text-align:left;
					}
				}
				tbody{
					border:0;
					background:#414141;
					tr{
						border:0;
						font-size:14px;
					}
					td:nth-child(1n){
						background:transparent;
						border:0;
						border-bottom:1px solid #4A4949AF;
						color:white;
						font-family: 'Montserrat', sans-serif;
						text-align:left;
					}
					td:nth-child(2n) {
						color:#909090;
					}
				}
			}
		}
	}
`
export const ProstheticskitStyles = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	#img-kit{
		z-index: 100;
	}
	background:linear-gradient(45deg, rgba(0,0,0,0.69) , rgba(74,73,73,0) 85%);
	border-radius:5px;
	width:100%;
	margin-top:5%;
	div::-webkit-scrollbar {
		width: 5px;
		height:5px;
		background:#5F5F5F;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #909090;
		width:95%;
	}
	.content-image{
		display:flex;
		justify-content:center;
		align-items:center;
		padding-top:5%;
		width:70%;
	}
	.content-tables{
		width:100%;
		display:flex;
		margin:auto;
		.content-table{
			width:100%;
			margin:0px 1% !important;
			padding:0px !important;
			.titles{
				h3{
					color:white;
					font-weight:700;
					text-align:left;
					font-size:20px;
					margin:0;
					font-family: 'Montserrat', sans-serif;
				}
				h4{
					color:#EBEBEB;
					font-size:15px;
					text-align:left;
					color:#909090;
					margin:0;
					font-family: 'Montserrat', sans-serif;
				}
			}
			.table-scroll{
				overflow-y:auto;
				overflow-x:hidden;
				border-radius:5px;
				display:flex;
				text-align:center;
				height:816px;
			}

			table{
				border:0;
				margin:0;
				thead{
					background: #4A4949;
					border-radius:5px;
					box-shadow: 0px 2px 4px black;
					tr{
						font-size:16px;
						font-family: 'Montserrat', sans-serif;
						border:0px;
					}
					th{
						padding: 10px;
						font-weight:600;
						background:#4A4949;
						text-align: center;
						border:0px;
						vertical-align: middle;
						position: sticky;
						top: 0;
						color:white;
						font-family: 'Montserrat', sans-serif;
					}
					th:nth-child(1n){
						text-align:left;
					}
				}
				tbody{
					border:0;
					background:#414141;
					tr{
						border:0;
						font-size:14px;
					}
					td:nth-child(1n){
						background:transparent;
						border:0;
						border-bottom:1px solid #4A4949AF;
						color:white;
						font-family: 'Montserrat', sans-serif;
						text-align:left;
					}
					td:nth-child(2n) {
						color:#909090;
					}
				}
			}
		}
	}
`