import styled from "styled-components";

/*----- Styles -----*/
export const FooterMobileStyles = styled.footer`
  background-color:#171717;
  padding:30px;
  .marginAddress{
    margin-right: 0%;
  }
  .footer-logo{
    width:198px;
    height:auto;
  }
  .information-content{
    display:flex;
    flex-direction:column;
    margin-top:30px;
    .information{
      display:flex;
      align-items:center;
      margin-top:10px;
      width:100%;
      height:min-content;
      position:relative;
      .content-image{
        margin-right:10px;
        img{
          position:relative;
          width:25px;
          height:25px;
        }
      }
      p{
        margin:0;
        padding:0;
        color:white;
        font-size:12px;
      }
      .link-email{
        color:#FFFFFF;
      }
    }
    .social-networks{
      margin-top:30px;
      img{
        margin-right:5px;
        width:30px;
        height:30px;
      }
    }
    .copyright-content{
      margin-top:20px;
      padding-top:15px;
      border-top:2px solid white;
      display:flex;
      justify-content:space-between;
      p{
        color:white;
        font-size:12px;
        margin:0;
        padding:0;
        font-weight:700;
      }
    }
    .legal-and-privacity-notice{
      margin:20px 0px 0px 0px;
      p{
        color:white;
        margin:0;
        font-size:12px;

      }
    }
  }

  .version-tag{
    text-align: left;
    font: normal normal normal 10px/30px Montserrat;
    letter-spacing: 0px;
    color: #969696;
    opacity: 1;
  }
`