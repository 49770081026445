import React from "react";
import { Image, Button, Col, Container, Row } from "react-bootstrap";
import { Styles } from "./FVconModalStyles"
import { navigate } from "hookrouter";
import "../../../commons/i18n";
import { Trans } from "react-i18next";

//images
import ConnessioneRevcon from '../../../assets/images/FVconModal/COnnessione REVCON_Stampa1.png'
import LogoFVcon from '../../../assets/images/FVconModal/LogoREVCON_Rojo-Blanco-chico.png'

const FVconModal = (props) => {
	return (
		<Styles>
			<Container className="modal-container" fluid>
				<Row className="close-row justify-content-end">
					<Button className="close close-btn" onClick={() => props.onButtonClose(props.currentModal)}
						>&times;</Button>
				</Row>
				<br></br>
				<Row>
					<Col lg={1}></Col>
					<Col lg={6}>
						<Row >
							<Image className="logoREVCON" src={LogoFVcon}></Image>
						</Row>
						<br></br>
						<br></br>
						<Row>
							<h3 className="title"><Trans>CCsmartImplants.FVconModal.title</Trans></h3>
							<h3 className="bold-title"><Trans>CCsmartImplants.FVconModal.subtitle</Trans></h3>
						</Row>
						<br></br>
						<Row>
							<Button className="main-btn" onClick={() => {
									props.onButtonClose(props.currentModal);
									navigate('/' + props.route);
							}}
							><Trans>CCsmartImplants.FVconModal.button</Trans></Button>
						</Row>
					</Col>

					<Col lg={4}>
						<Image src={ConnessioneRevcon}></Image>
					</Col>
					<Col lg={1}></Col>
				</Row>
			</Container>
		</Styles>
	)
}
export default FVconModal;