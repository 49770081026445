import styled from 'styled-components';

const StylesGlobalMobile = styled.div`
  position:absolute;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  html,body{
    width:100%;
    height:100%;
  }
  h1, h2, h3, h4, p, a, div, button, li {
    font-family: 'Montserrat', sans-serif;
  }

  .blue-dot{
    color: #D72B52;
  }
`;

export default StylesGlobalMobile