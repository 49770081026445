import React from 'react';
import { Trans } from 'react-i18next';
/*import { Image } from 'react-bootstrap';*/
import SurgicalInstrumentsStyles from './styles';
import RegisterMark from '../../../../../components/registerMark';

const SurgicalInstruments = ({ content }) => {
  const renderList = () => {
    let render = [];
    content.items.forEach(element => {
      render.push(
        <div className="element">
          <span className="dot"></span>
          <p><Trans>{element}</Trans></p>
        </div>
      )
    });
    return render;
  }
  return (
    <SurgicalInstrumentsStyles id="prosthetic-components">
      <div className="system-text">
        <p><Trans><RegisterMark>{content.text}</RegisterMark></Trans></p>
        <div className="system-list">
          {renderList()}
        </div>
      </div>
    </SurgicalInstrumentsStyles >
  )
}

export default SurgicalInstruments;