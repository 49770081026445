import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom'
import Arrow from "../../assets/icons/icon-components/Arrow.js"
import { navigate } from 'hookrouter';

import styled from 'styled-components'

const BackButtonStyle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	.button-content{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		p{
			margin: 0 10px;
			font-size: 13px;
			font-weight: 700;
		}
		.arrow-content{
			display: flex;
			align-items: center;
		}
	}
`;

const BackButton = ({ history, path }) => {
	return (
		<BackButtonStyle id={'backButton'} onClick={() => { 
			if( path ){
				navigate(path) 
			}else{
				history.goBack()
			}
		}} >
			<div className="button-content">
				<Arrow rotateDeg="0" color="#D72B52" width="11px" heigth="11px" strokeWidth="40px" />
				<p><Trans>general.back</Trans></p>
			</div>
		</BackButtonStyle>
	)
}

export default withRouter(BackButton)