import React from 'react';
import { Video } from './Styles';

export const videos = [
	'https://www.youtube.com/embed/JKVmz85bguE',
	'https://www.youtube.com/embed/EVYT3WhO0ig',
	'https://www.youtube.com/embed/BXBVvrWYUbU',
	'https://www.youtube.com/embed/pO8wQnmor8M',
	'https://www.youtube.com/embed/y4rz1cYKXU4',
	'https://www.youtube.com/embed/wcCR0WiRFdo',
	'https://www.youtube.com/embed/vuqlOevtoDo',
	'https://www.youtube.com/embed/6hR_2QTttW8',
	'https://www.youtube.com/embed/JD9rkDUxl7w',
	'https://www.youtube.com/embed/OMVWkzpM_Iw',
	'https://www.youtube.com/embed/7ZQzRO2Vs8k',
	'https://www.youtube.com/embed/X7BAeXfiPAU',
	'https://www.youtube.com/embed/_2mZKK0Y_dk',
	'https://www.youtube.com/embed/tEUPFM7PTq4',
	'',
]

export const renderVideos = (width, height) =>
	videos.map((video, id) =>
		<Video>
			<iframe title={`video_${id}`} width={width} height={height} src={video}
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen>
			</iframe>
		</Video>
	)