import React from "react";
import { Image, Button, Col, Container, Row } from "react-bootstrap";
import { Styles } from "./FVconModalMobileStyles"
import { navigate } from "hookrouter";
import "../../../../commons/i18n";
import { Trans } from "react-i18next";

//images
import ConnessioneRevcon from '../../../../assets/images/FVconModal/mobile/COnnessione REVCON_Stampa1.png'
import LogoFVcon from '../../../../assets/images/FVconModal/mobile/LogoREVCON_Rojo-Blanco-chico.png'

const FVconModalMobile = (props) => {
	return (
		<Styles>
			<Container className="modal-container" fluid>
				<Row className="close-row justify-content-end">
					<Button className="close close-btn" onClick={() => props.onButtonClose(props.currentModal)}
						>&times;</Button>
				</Row>
				<br></br>
				<Row>
					<Col lg md sm xs={6}>
						<br></br>
						<Row className="justify-content-center align-content-center">
							<Image className="logoREVCON" src={LogoFVcon}></Image>
						</Row>
					</Col>
					<Col lg md sm xs={6}>
						<Image src={ConnessioneRevcon}></Image>
					</Col>					
					
				</Row>
				<Row>
					<h3 className="title"><Trans>CCsmartImplants.FVconModal.title</Trans></h3>
					<h3 className="bold-title"><Trans>CCsmartImplants.FVconModal.subtitle</Trans></h3>
					</Row>
					<br></br>
					<Row className="justify-content-center align-content-center">
						<Button className="main-btn" onClick={() => {
								props.onButtonClose(props.currentModal);
								navigate('/' + props.route);
						}}
						><Trans>CCsmartImplants.FVconModal.button</Trans></Button>
					</Row>
					<br></br>
			</Container>
		</Styles>
	)
}
export default FVconModalMobile;