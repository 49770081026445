import React from 'react'
import Styles from './Styles'

import { Trans } from "react-i18next";

import AccordionCard from '../../../components/accordion/AccordionCard'
import DentalIconOne from "../../../assets/images/dental_icon_one.svg";
import DentalIconThree from "../../../assets/images/Implant-Assembly.svg";
import DentalIconTwo from "../../../assets/images/dental_icon_two.svg";
import Nanopore from "../../../assets/images/Dental/nanopore.png";
import OsteoPore from "../../../assets/images/Dental/osteopore.png"
import OneLevelList from './components/OneLevelList'
import ImageScrew from '../../../assets/images/Dental/implantes-mobile.png'
import MicroscopeImg from "../../../assets/images/Dental/speed.png";


import { Row, Container, Col, Image } from "react-bootstrap";
import RegisterMark from '../../../components/registerMark';

const Dental = () => {

	return (
		<Styles>
			<div className="container-image">
				<Image src={ImageScrew} />
			</div>
			<div className="microscpe-image">
				<Image src={MicroscopeImg} />
			</div>
			<div className="container-page">

				<div className="container-title">
					<h1>Dental<span className="blue-dot">.</span></h1>
					<h3><RegisterMark>dental.landscape_body</RegisterMark></h3>
				</div>

				<div className="container-cards">

					<AccordionCard
						image={DentalIconOne}
						title={<Trans>dental.title_card_two_part_one</Trans>}
						subtitle={<Trans>dental.title_card_two_part_two</Trans>}
						inside={true}
						arrowColor={'#D72B52'}
						content={
							<Container fluid>
								<Row>
									<Col xs={3} className="first-col" />

									<Col xs={9}>
										<p className="card-description"><RegisterMark>dental.body_card_two_short</RegisterMark></p>
									</Col>
								</Row>
							</Container>}
					/>

					<AccordionCard
						image={DentalIconThree}
						title={<Trans>dental.title_card_three_part_one</Trans>}
						subtitle={<Trans>dental.title_card_three_part_two</Trans>}
						inside={true}
						arrowColor={'#D72B52'}
						content={
							<Container fluid>
								<Row>
									<Col xs={3} className="first-col" />

									<Col xs={9}>
										<p className="card-description"><RegisterMark>dental.body_card_three</RegisterMark></p>
									</Col>
								</Row>
							</Container>
						}
					/>

				</div>
				<div className="container-title container-surface">
					<h5 className="title">
						<Trans>dental.implantologia_popup.card_2.title</Trans>
						<span> & </span>  
						<Trans>dental.implantologia_popup.card_1.title</Trans>
					</h5>
					<Container fluid className="image-title">
						<Row>
							<Col xs={3} className="first-col">
								<Image alt="" className="card-image" src={DentalIconTwo}></Image>
							</Col>
							<Col xs={9} className="second-col">
								<h2><Trans>dental.title_card_four_part_one_small</Trans><br />
									<Trans>dental.title_card_four_part_one_small_two</Trans></h2>
							</Col>
						</Row>
					</Container>
					<p><RegisterMark>dental.body_card_four</RegisterMark></p>
					<p><Trans>dental.body_card_four_four</Trans></p>
				</div>

				<div className="container-cards">
					<AccordionCard
						classNameCard = "implantologia"
						image={Nanopore}
						title={<><Trans>dental.implantologia_popup.card_2.title</Trans><span>&nbsp;</span></>}
						inside={true}
						arrowColor={'#D72B52'}
						content={
							<Container fluid>
								<Row>
									<Col xs={3} className="first-col" />

									<Col xs={9}>
										<p className="card-description"><Trans>dental.implantologia_popup.card_2.text</Trans></p>
									</Col>
								</Row>
							</Container>
						}
					/>

					<AccordionCard
						classNameCard = "implantologia"
						image={OsteoPore}
						title={<><Trans>dental.implantologia_popup.card_1.title</Trans><span>&nbsp;</span></>}
						inside={true}
						arrowColor={'#D72B52'}
						content={
							<Container fluid>
								<Row>
									<Col xs={3} className="first-col" />

									<Col xs={9}>
										<p className="card-description"><Trans>dental.implantologia_popup.card_1.text</Trans></p>
									</Col>
								</Row>
							</Container>
						}
					/>
				</div>



				<div className="container-title container-implants">
					<h3><RegisterMark>dental.title_card_one_1</RegisterMark><br></br><RegisterMark>dental.title_card_one_2</RegisterMark><span className="blue-dot">.</span></h3>
					<p><RegisterMark>dental.body_card_one</RegisterMark></p>
					<p><RegisterMark>dental.body_card_one_one</RegisterMark></p>
				</div>

				<div className="container-title container-dental">
					<h5 className="title"><RegisterMark>dental.systems_upper_title</RegisterMark></h5>
					<h2><RegisterMark>dental.systems_title_mobile</RegisterMark><span className="blue-dot">.</span></h2>
					<p><RegisterMark>dental.system_faico_dental.subtitle</RegisterMark></p>

					<OneLevelList />
				</div>

			</div>


		</Styles>
	)
}

export default Dental