import React, { useState } from 'react';
import { Form, Button, ButtonToolbar } from 'react-bootstrap';
import { Trans } from "react-i18next";
import { withTranslation } from 'react-i18next';
import { navigate } from "hookrouter";

const ContactForm = (props) => {
	const [name, setName] = useState('')
	const [surname, setSurname] = useState('')
	const [email, setEmail] = useState('')
	const [company, setCompany] = useState('')
	const [formMessage, setFormMessage] = useState('')
	const [sending, setSending] = useState(false)

	const cleanForm = () => {
		setName('')
		setSurname('')
		setEmail('')
		setCompany('')
		setFormMessage('')
	}

	const validateEmail = (email) => {
		const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ // eslint-disable-line
		return re.test(email);
	}

	const sendEmail = async (e) => {

		e.preventDefault()
		let error = ""
		if (!name.length) {
			error = t('contact.contactForm.inputName.error')
		} else if (!email.length) {
			error = t('contact.contactForm.inputEmail.error')
		} else if (!validateEmail(email)) {
			error = t('contact.contactForm.inputEmail.invalid')
		}

		if (error.length) {
			setFormMessage(error)
			return
		}
		setSending(true)

		let params = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		}
		
		const faicoEmail = "info@aonimplants.us";
		const webfaicoEmail = "web@aonimplants.us";

		let infoEmailParams = [
			{
				"token": "#{email}#",
				"value": email
			},
			{
				"token": "#{name}#",
				"value": name
			},
			{
				"token": "#{surname}#",
				"value": surname
			},
			{
				"token": "#{company}#",
				"value": company
			}
		]

		let firstEmail = {
			...params,
			body: JSON.stringify({
				sender: name,
				sender_email: webfaicoEmail,
				recipients: [faicoEmail],
				subject: "Download AonImplants", 
				template_name: "downloadEmail",
				template_params: {
					"header": "Nuevo mensaje desde la web",
					"body": infoEmailParams
				},
				reply_to: [email]
			})
		}

		let response = await fetch('https://aonimplants-test.b4code.com/contacto/contacto.php', firstEmail)

		if (response.ok) {
            cleanForm()
            props.onSubmit()
		}
		else {
			setFormMessage(t('contact.contactForm.errorAction'))
		}
		setSending(false)
	}

	const getButtonClass = () => {
		if (!formMessage.length) {
			return "message-hidden"
		} else if (formMessage === t('contact.contactForm.successAction')) {
			return "form-success"
		}
	}

	const { t } = props;
	return (
		<>
			<h1 className="my-3">
				<Trans>contact.contactForm.title_modal</Trans>
			</h1>

			<Form className="contact-form">
				<Form.Group>
					<Form.Control
						type="text"
						placeholder={'*' + t('contact.contactForm.inputName.placeholder')}
						onChange={(e) => { setName(e.currentTarget.value) }}
						required
						value={name}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="text"
						placeholder={t('contact.contactForm.inputSurname.placeholder')}
						onChange={(e) => { setSurname(e.currentTarget.value) }}
						required
						value={surname}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="email"
						placeholder={'*' + t('contact.contactForm.inputEmail.placeholder')}
						onChange={(e) => { setEmail(e.currentTarget.value) }}
						required
						value={email}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="text"
						placeholder={t('contact.contactForm.inputQuery.placeholder')}
						onChange={(e) => { setCompany(e.currentTarget.value) }}
						required
						value={company}
					/>
				</Form.Group>

				<ButtonToolbar className="container-button my-4">
                    
					<Button
						className='contact-form-button zoomButton main-btn'
						variant="secondary"
						type="submit"
						onClick={(e) => {
                                sendEmail(e)
                                localStorage.setItem("DownloadFormSubmited", "true")
                            }
                        }
						disabled={sending}
					>
						{sending ?
							<Trans>contact.contactForm.buttonProgress</Trans>
							:
							<Trans>contact.contactForm.button</Trans>
						}


					</Button>
                    <Button
						className='contact-form-button zoomButton main-btn'
						variant="secondary"
						type="submit"
						onClick={(e) => 
                            props.onCancel()
                        }
						disabled={sending}
					>
							<Trans>contact.contactForm.buttonCancel</Trans>
					</Button>
				</ButtonToolbar>
				<div className={`form-message ${getButtonClass()}`}>
					{formMessage}
				</div>
			</Form>
		</>
	)
}

export default withTranslation(['es']['contact'])(ContactForm)
