/*----- Assets -----*/
import DentalImage from "../../assets/images/dental_placeholder.png";
import CranioImage from "../../assets/images/download-craneo-maxilofacial.png";
import DocumentImage from "../../assets/images/document.svg"

const data = [
	{
		title: 'descarga.catalog_1.title',
		image: DentalImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_1.items.1',
				refSpanish: '/pdf/Español/1_CatalogosDentalesyDocumentos/AoN_Implants_Catálogo_Dental 2021_(SP).pdf',
				refEnglish: '/pdf/Ingles/1_Dental_Catalog_And_Documents/AoN_Implants_Dental_Catalog_2021_(EN).pdf'
			},
			{
				title: 'descarga.catalog_1.items.2',
				refSpanish: '/pdf/Español/1_CatalogosDentalesyDocumentos/AoN_Implants_Conexión_Cono_Morse_REVCON_2021_(SP).pdf',
				refEnglish: '/pdf/Ingles/1_Dental_Catalog_And_Documents/AoN_Implants_REVCON_Morse_Cone_Connection_2021_(EN).pdf'
			},
			/*{
				title: 'descarga.catalog_1.items.3',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Conexion Hexagono Externo_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO External Hexagon Connection_(EN).pdf"
			},*/
			{
				title: 'descarga.catalog_1.items.4',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/AoN_Guía_Quirúrgica&Manual_Protésico_CC_HI_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/AoN_Surgical_Guide_&_Prosthetic_Manual_CC_IH_(EN).pdf"
			}
			/*{
				title: 'descarga.catalog_1.items.5',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Guía Quirúrgica & Manual Protésico_HE_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO Surgical Guide & Prosthesis Manual_EH_(EN).pdf"
			}*/
		],
		name: 'imageDental'
	},
	{
		title: 'descarga.catalog_2.title',
		image: CranioImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_2.items.1',
				refSpanish: '/pdf/Español/2_CatalogoMaxilofacialyDocumentos/AoN_Implants_Maxillofacial_Catalog_(EN-SP).pdf',
				refEnglish: '/pdf/Ingles/2_Maxillofacial_Catalog_and_Documents/AoN_Implants_Maxillofacial_Catalog_(EN-SP).pdf'
			},
		]
	},
	{
		title: 'descarga.catalog_3.title',
		image: DocumentImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_3.items.1',
				refSpanish: "/pdf/Español/3_IFU/IFU_192015_AoN_Implants_US_Dental_System_(EN-SP).pdf",
				refEnglish: "/pdf/Ingles/3_IFU/IFU_192015_AoN_Implants_US_Dental_System_(EN-SP).pdf"
			},
			{
				title: 'descarga.catalog_3.items.2',
				refSpanish: "/pdf/Español/3_IFU/IFU_191997_AoN_Implants_CMF_System_(EN-SP).pdf",
				refEnglish: "/pdf/Ingles/3_IFU/IFU_191997_AoN_Implants_CMF_System_(EN-SP).pdf"
			},
		]
	},
	/*{
		title: 'descarga.catalog_4.title',
		image: DocumentImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_4.items.1',
				refSpanish: "/pdf/Español/4_Certificados/FDA_K192015 FAICO Dental Implant System.pdf",
				refEnglish: "/pdf/Ingles/4_Certificates/FDA_K192015 FAICO Dental Implant System.pdf"
			},
			{
				title: 'descarga.catalog_4.items.2',
				refSpanish: "/pdf/Español/4_Certificados/FDA_K191997 FAICO CMF System.pdf",
				refEnglish: "/pdf/Ingles/4_Certificates/FDA_K191997 FAICO CMF System.pdf"
			},
		]
	},*/
]

export default data;